import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';

@Component({
	selector: 'app-empty-table',
	templateUrl: './empty-table.component.html',
	styleUrls: ['./empty-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyTableComponent implements OnChanges {
	@Input() type:
		'users' |
		'profile' |
		'rules' |
		'clients' |
		'sources' = 'profile';
	@Input() showImg = true;
	@Input() disabled = false;

	@Output() readonly newUser = new EventEmitter();
	@Output() readonly newRule = new EventEmitter();
	@Output() readonly newClient = new EventEmitter();
	@Output() readonly newSource = new EventEmitter();

	title = '';
	subTitle = '';
	buttonText = '';
	optionalButtonText = '';
	icon = '';

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes['type'] ) {
			switch (this.type) {
				case 'users':
					this.title = '';
					this.subTitle = '';
					this.buttonText = 'users_roles.users.create';
					this.icon = 'icon-plus';
					break;
				case 'profile':
					this.title = '';
					this.subTitle = '';
					this.buttonText = '';
					this.icon = '';
					break;
				case 'rules':
					this.title = '';
					this.subTitle = '';
					this.buttonText = 'rules.add';
					this.icon = 'icon-plus';
					break;
				case 'clients':
					this.title = '';
					this.subTitle = '';
					this.buttonText = 'clients.add';
					this.icon = 'icon-plus';
					break;
				case 'sources':
					this.title = '';
					this.subTitle = '';
					this.buttonText = 'sources.add';
					this.icon = 'icon-plus';
					break;
				default:
					break;
			}
		}
	}

	clicked(): void {
		switch (this.type) {
			case 'users':
				this.newUser.emit(undefined);
				break;
			case 'rules':
				this.newRule.emit(undefined);
				break;
			case 'clients':
				this.newClient.emit(undefined);
				break;
			case 'sources':
				this.newSource.emit(undefined);
				break;
			default:
				break;
		}
	}

	optionalButtonClicked(): void {
		switch (this.type) {
			default:
				break;
		}
	}
}
