import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { Client, FieldsOfResponse, HlrInterface, Product } from '@helpers/types/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlarisEditPanelService, CustomValidators, EditPanelInputData } from '@campaign-portal/components-library';
import { ProductsService } from '@helpers/repo/products.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { ClientsService } from '../clients.service';
import { debounceTime } from 'rxjs';

@Component({
	selector: 'app-edit-client',
	templateUrl: './edit-client.component.html',
	styleUrl: './edit-client.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditClientComponent implements OnInit {
	readonly errors: { key: string; value: string }[] = [
		{key: 'mask', value: 'gl.errors.invalid_format'},
		{key: 'required', value: 'gl.errors.required'}];
	readonly client: Client = {
		id: null,
		login: '',
		password: '',
		productId: '',
		availableInterfaces: ['http'],
		removeLeadingZeroFromMnc: false,
		maxSessions: 1000,
		enumAddressList: [],
		fieldsOfResponse: ['dnis', 'login', 'mccmnc', 'ported', 'result',  'source_name', 'source_type'],
		allowDebug: false,
		forceResultToOne: false
	};
	readonly clientForm = new FormGroup({
		login: new FormControl('', { nonNullable: true, validators: Validators.required }),
		password: new FormControl('', { nonNullable: true, validators: Validators.required }),
		productId: new FormControl(''),
		availableInterfaces: new FormControl<HlrInterface[]>([], CustomValidators.requiredArrayOrNull),
		removeLeadingZeroFromMnc: new FormControl(false, { nonNullable: true }),
		maxSessions: new FormControl(
			1000,
			{
				nonNullable: true,
				validators: [
					Validators.required,
					Validators.pattern('^\\d+$'),
					Validators.min(1),
					Validators.max(10000)
				]
			}),
		enumAddressList: new FormControl<string[]>(
			{ disabled: true, value: [] },
			{
				nonNullable: true,
				validators: [
					Validators.required,
					CustomValidators.requiredArrayOrNull
				]
			}
		),
		fieldsOfResponse: new FormControl<FieldsOfResponse[]>(
			{ disabled: true, value: [] },
			CustomValidators.requiredArrayOrNull
		),
		allowDebug: new FormControl(false),
		forceResultToOne: new FormControl(false),
	});

	readonly fields: FieldsOfResponse[] = ['mccmnc', 'result', 'ported', 'source_name', 'source_type',
		'dnis', 'login', 'present', 'roaming', 'message', 'message_id',
		'rate', 'wireless', 'cached', 'context_log', 'providerResponseCode'];
	readonly interfaces: HlrInterface[] = ['http', 'enum', 'smsrt'];
	products: Product[] = [];
	readonly productsFilter = new FormControl('');
	readonly separator = ', ';
	readonly noneProduct = {
		product_id: '',
		product_caption: '',
		acc_currency_code: '',
		product_direction: 0,
		product_type: 0
	} as unknown as Product;
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: { client?: Client },
		public readonly clientsService: ClientsService,
		public readonly productsService: ProductsService,
		private readonly editPanel: AlarisEditPanelService
	) {
		if ( this.inputData.client ) {
			this.client = this.inputData.client;
		}
	}

	ngOnInit(): void {
		this.productsService.clientProducts$
			.pipe((takeUntilDestroyed(this.destroyRef)))
			.subscribe((list) => {
				this.productsFilter.reset();
				this.products = [this.noneProduct, ...list];
			});

		this.productsFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(150))
			.subscribe((value) => {
				this.products = [
					this.noneProduct,
					...this.productsService.clientProducts.filter((product) => {
						return product.product_caption.toLowerCase().includes(value?.toLowerCase() ?? '');
					})
				];
			});

		this.clientForm.controls.availableInterfaces.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				if ( value === null ) {
					this.clientForm.controls.fieldsOfResponse.enable();
					this.clientForm.controls.enumAddressList.enable();
				} else {
					const httpType = value.includes('http');
					const enumType = value.includes('enum');

					this.clientForm.controls.fieldsOfResponse[httpType ? 'enable' : 'disable']();
					this.clientForm.controls.fieldsOfResponse
						.patchValue(httpType ? this.clientForm.controls.fieldsOfResponse.value : []);
					this.clientForm.controls.enumAddressList[enumType ? 'enable' : 'disable']();
					this.clientForm.controls.enumAddressList
						.patchValue(enumType ? this.clientForm.controls.enumAddressList.value : []);
				}
			});
		this.reset();
	}

	displayProduct: (id: Id<exist>) => string = (id: Id<exist>): string => {
		const product = this.productsService.map.get(+id);
		if ( product ) {
			return `${product.product_caption} (${product.acc_currency_code})`;
		}
		return '';
	};


	reset(): void {
		const client = structuredClone(this.client);
		this.clientForm.reset(client);
	}

	save(): void {
		const client: Client = {
			id: this.client.id,
			login: this.clientForm.controls.login.value,
			password: this.clientForm.controls.password.value,
			productId: this.clientForm.controls.productId.value + '' ?? '',
			availableInterfaces: this.clientForm.controls.availableInterfaces.value === null
				? this.interfaces
				: this.clientForm.controls.availableInterfaces.value,
			removeLeadingZeroFromMnc: this.clientForm.controls.removeLeadingZeroFromMnc.value,
			maxSessions: +this.clientForm.controls.maxSessions.value,
			enumAddressList: this.clientForm.controls.enumAddressList.value,
			fieldsOfResponse: this.clientForm.controls.fieldsOfResponse.value === null
				? this.fields
				: this.clientForm.controls.fieldsOfResponse.value ?? [],
			allowDebug: this.clientForm.controls.allowDebug.value,
			forceResultToOne: this.clientForm.controls.forceResultToOne.value
		};
		this.clientsService.update(client)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if (
					resp.data[0]?.id
				) {
					this.editPanel.close(true);
				}
			});
	}

	close(): void {
		this.editPanel.close(false);
	}

	generatePassword(): void {
		this.clientForm.get('password')?.setValue(Math.random().toString(36).slice(-8));
	}
}
