<form (ngSubmit)="save()" [formGroup]="userForm">
	<div [alarisLoading]="usersService.loading$">
		<div class="edit-panel-header">
			<alaris-icon name="icon-add-contact"></alaris-icon>
			{{ user.ID ? ('users_roles.users.edit' | translate) : ('users_roles.users.create' | translate) }}
		</div>
		<div [alarisFade]="'vertical'" class="form-group panel-content">
			<alaris-input formControlName="login">
				{{ 'users_roles.users.login' | translate }}
			</alaris-input>
			<alaris-input formControlName="email">
				{{ 'users_roles.users.email' | translate }}
			</alaris-input>
			@if (!user.ID) {
				<alaris-input formControlName="password" type="password">
					{{ 'users_roles.users.password' | translate }}
				</alaris-input>
			}
			<alaris-input formControlName="firstName">
				{{ 'users_roles.users.first_name' | translate }}
			</alaris-input>
			<alaris-input formControlName="lastName">
				{{ 'users_roles.users.last_name' | translate }}
			</alaris-input>
			<alaris-select2 [expandable]="true"
							[label]="'gl.page_size' | translate"
							formControlName="pageSize"
							mode="string">
				<alaris-options2 *alarisVirtualFor="let size of pageSize; let index"
								 [value]="size"
								 [virtualIndex]="index">
					{{ size }}
				</alaris-options2>
			</alaris-select2>
			<button (click)="reset()" alaris-button bType="secondary" class="reset-button" size="lg" type="button">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'gl.reset' | translate }}
			</button>
		</div>
		<div class="edit-panel-footer">
			<button [disabled]="userForm.invalid || !userForm.dirty"
					alaris-button bType="primary"
					size="lg"
					type="submit">
				<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
				{{ 'gl.submit' | translate }}
			</button>
			<button (click)="close()" alaris-button bType="outline" size="lg" type="button">
				{{ 'actions.close' | translate }}
			</button>
		</div>
	</div>
</form>

