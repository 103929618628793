import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Data, Router, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisToasterService,
	TokenStatus
} from '@campaign-portal/components-library';
import { UserService } from './user.service';
import { STORAGE } from '@helpers/types/consts';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService {

	readonly currentRouteData = new BehaviorSubject<Data | null>(null);
	readonly parentRoute = new BehaviorSubject<ActivatedRouteSnapshot | null>(null);

	constructor(
		private readonly authService: AuthService,
		private readonly storage: AlarisLocalStorageService,
		private readonly toaster: AlarisToasterService,
		private readonly lSerive: AlarisLanguageService,
		private readonly router: Router,
		private readonly user: UserService
	) {
	}

	canActivate(
		route: ActivatedRouteSnapshot
		// state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
		if ( this.authService.logged ) {
			this.currentRouteData.next(route.data);
			this.parentRoute.next(route.parent);
			const { permissions } = route.data;
			if ( permissions ) {
				return this.user.allowed(permissions)
					? true
					: this.router.parseUrl('');
			}
			return true;
		}

		if (
			this.authService.checkTokenExpired(this.storage.get(STORAGE.TOKEN)) === TokenStatus.EXPIRED
		) {
			this.toaster.error(
				this.lSerive.translate('errors.unauthorizedExtraError'),
				this.lSerive.translate('signIn.title')
			);
		}

		this.currentRouteData.next(null);
		this.parentRoute.next(null);
		this.authService.logOut();
		return false;
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot
		// state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(
			childRoute
			// state
		);
	}

}

export const AuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot
): Observable<boolean> | Promise<boolean> | boolean | UrlTree => {
	return inject(AuthGuardService).canActivate(route);
};
