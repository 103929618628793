import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UserService } from '../../auth/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from './settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HlrSettings } from '@helpers/types/models';
import { of, switchMap, tap } from 'rxjs';
import { AlarisDialogService } from '@campaign-portal/components-library';
import { ImportSettingsDialogComponent } from './dialog/dialog.component';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrl: './settings.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
	readonly errors: { key: string; value: string }[] = [
		{key: 'mask', value: 'gl.errors.invalid_format'},
		{key: 'required', value: 'gl.errors.required'}];
	readonly form = new FormGroup({
		settingId: new FormControl<number>(0, { nonNullable: true }),
		cacheStorageLimit: new FormControl<boolean | null>(null),
		enableGlobalProducts: new FormControl<boolean | null>(null),
		enableGlobalSenders: new FormControl<boolean | null>(null),
		apiHost: new FormControl<string | null>(null),
		concurrentRequestTimeout: new FormControl<number>(0, {
			nonNullable: true,
			validators: [Validators.required, Validators.pattern('\\d{0,99}')]
		}),
		defaultConnectionTimeout: new FormControl<number>(0, {
			nonNullable: true,
			validators: [Validators.required, Validators.pattern('\\d{0,99}')]
		}),
		defaultRequestTimeout: new FormControl<number>(0, {
			nonNullable: true,
			validators: [Validators.required, Validators.pattern('\\d{0,99}')]
		}),
		dnisLimit: new FormControl<number>(0, {
			nonNullable: true,
			validators: [Validators.pattern('\\d{0,99}')]
		})
	});

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		public readonly user: UserService,
		public readonly settingsService: SettingsService,
		private readonly dialog: AlarisDialogService
	) {
	}

	ngOnInit(): void {
		this.settingsService.read()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.form.patchValue(resp);
			});
	}

	update(): void {
		const hlrSettings: HlrSettings = {
			settingId: this.form.controls.settingId.value,
			apiHost: this.form.controls.apiHost.value,
			cacheStorageLimit: this.form.controls.cacheStorageLimit.value,
			concurrentRequestTimeout: +this.form.controls.concurrentRequestTimeout.value,
			defaultConnectionTimeout: +this.form.controls.defaultConnectionTimeout.value,
			defaultRequestTimeout: +this.form.controls.defaultRequestTimeout.value,
			dnisLimit: +this.form.controls.dnisLimit.value,
			enableGlobalProducts: this.form.controls.enableGlobalProducts.value,
			enableGlobalSenders: this.form.controls.enableGlobalSenders.value
		};
		this.settingsService.update(hlrSettings)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.form.markAsPristine();
				},
				error: () => {
					this.ngOnInit();
				}
			});
	}

	import(): void {
		this.dialog.open<boolean>(ImportSettingsDialogComponent).closed
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				switchMap((result) => {
					if ( result ) {
						return this.settingsService.importConfig()
							.pipe(
								takeUntilDestroyed(this.destroyRef),
								switchMap(() => {
									return this.settingsService.exportConfig();
								}),
								tap((resp) => {
									if ( resp.reply === 'Success' ) {
										if ( this.form.dirty ) {
											this.form.markAsPristine();
										}
									}

								})
							);
					} else {
						return of({ reply: '' });
					}
				})
			)
			.subscribe();
	}

}
