<form (ngSubmit)="save()" [formGroup]="clientForm">
	<div [alarisLoading]="clientsService.loading$">
		<div class="edit-panel-header">
			<alaris-icon name="icon-add-contact"></alaris-icon>
			{{ client.id ? ('clients.edit' | translate) : ('clients.add' | translate) }}
		</div>
		<div [alarisFade]="'vertical'" class="form-group panel-content">
			<alaris-input formControlName="login" [errors]="errors">
				{{ 'clients.login' | translate }}
			</alaris-input>
			<div class="password-form">
				<alaris-input formControlName="password" autocomplete="new-password" type="password"
							  [errors]="errors">
					{{'clients.password' | translate}}
				</alaris-input>
				<button (click)="generatePassword()" alaris-button bType="outline" type="button">
					{{ 'clients.generate' | translate }}
				</button>
			</div>
			<alaris-select2 [alarisLoading]="productsService.loading$" [backdrop]="false"
							[displayWith]="displayProduct.bind(this)"
							[expandable]="true"
							[label]="'clients.client_product' | translate"
							formControlName="productId"
							mode="string">
				<alaris-select-filter2 [formControl]="productsFilter"></alaris-select-filter2>
				<alaris-options2 *alarisVirtualFor="let product of products; let index"
								 [value]="product.product_id.toString()"
								 [virtualIndex]="index">
					<span *ngIf="product.product_id !== ''">
						{{ product.product_caption }}
						({{ product.acc_currency_code }})
					</span>
					<span *ngIf="product.product_id === ''">
						{{ 'gl.none' | translate }}
					</span>
				</alaris-options2>
			</alaris-select2>

			<alaris-multi-select2 [label]="'clients.available_interfaces' | translate"
								  [optionsCount]="interfaces.length"
								  formControlName="availableInterfaces" mode="tag" [errors]="errors">
				@for (int of interfaces; track int) {
					<alaris-multi-options2 [value]="int">{{ int |  uppercase }}</alaris-multi-options2>
				}
			</alaris-multi-select2>

			<alaris-checkbox formControlName="allowDebug">
				{{ 'clients.allow_debug' | translate }}
			</alaris-checkbox>
			<alaris-checkbox formControlName="removeLeadingZeroFromMnc">
				{{ 'clients.remove_leading_zero_from_mnc'  | translate }}
			</alaris-checkbox>
			<alaris-checkbox formControlName="forceResultToOne">
				{{ 'clients.force_result_to_one'  | translate }}
			</alaris-checkbox>

			<alaris-input formControlName="maxSessions" type="number">
				{{ 'clients.max_sessions' | translate }}
			</alaris-input>

			@if (clientForm.controls.availableInterfaces.value === null
			|| clientForm.controls.availableInterfaces.value.includes('enum')) {
				<alaris-text-area [expandable]="true"
								  [placeholder]="'gl.textAreaHint' | translate"
								  formControlName="enumAddressList"
								  maxHeight="none"
								  mode="tag"
								  [errors]="errors">
					{{ 'clients.enum_address_list' | translate }}
				</alaris-text-area>
			}

			@if (clientForm.controls.availableInterfaces.value === null
			|| clientForm.controls.availableInterfaces.value.includes('http')) {
				<alaris-multi-select2 [expandable]="true"
									  [label]="'clients.fields_of_response' | translate"
									  [optionsCount]="fields.length"
									  formControlName="fieldsOfResponse" mode="tag" [errors]="errors">
					@for (field of fields; track field) {
						<alaris-multi-options2 [value]="field">{{ field }}</alaris-multi-options2>
					}
				</alaris-multi-select2>
			}

			<button (click)="reset()" alaris-button bType="secondary" class="reset-button" size="lg" type="button">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'gl.reset' | translate }}
			</button>
		</div>
		<div class="edit-panel-footer">
			<button [disabled]="clientForm.invalid || !clientForm.dirty"
					alaris-button bType="primary"
					size="lg"
					type="submit">
				<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
				{{ 'gl.submit' | translate }}
			</button>
			<button (click)="close()" alaris-button bType="outline" size="lg" type="button">
				{{ 'actions.close' | translate }}
			</button>
		</div>
	</div>
</form>
