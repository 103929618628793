import { Injectable, OnDestroy } from '@angular/core';
import { AbstractCRUDService } from '@campaign-portal/components-library';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Injectable({
	providedIn: 'root'
})
export class RulesFieldsService implements AbstractCRUDService, OnDestroy {

	readonly ruleId: EntityField = {
		id: 0,
		name: 'rules.id',
		variable: 'ruleId',
		filterType: FilterType.LIKE,
		required: false,
		type: InputType.TEXT
	};
	readonly name: EntityField = {
		id: 1,
		name: 'rules.name',
		variable: 'name',
		filterType: FilterType.LIKE,
		required: false,
		type: InputType.TEXT
	};
	readonly choiceListName: EntityField = {
		id: 2,
		name: 'rules.choice_list',
		variable: 'choiceListName',
		filterType: FilterType.LIKE,
		required: false,
		type: InputType.TEXT
	};
	readonly prefixList: EntityField = {
		id: 3,
		name: 'rules.prefix_list',
		variable: 'prefixList',
		filterType: FilterType.LIKE,
		required: false,
		type: InputType.TEXT
	};
	readonly productList: EntityField = {
		id: 4,
		name: 'rules.product_list',
		variable: 'productList',
		filterType: FilterType.LIKE,
		required: false,
		type: InputComplexType.MULTISELECT,
		data: []
	};
	readonly senderList: EntityField = {
		id: 5,
		name: 'rules.sender_list',
		variable: 'senderList',
		filterType: FilterType.LIKE,
		required: false,
		type: InputType.TEXT
	};

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly headers: EntityField[] = [
		this.ruleId,
		this.name,
		this.choiceListName,
		this.prefixList,
		this.productList,
		this.senderList
	];

	constructor() {}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});

	}

	create(): Observable<void> {
		return of();
	}

	update(): Observable<void> {
		return of();
	}

	delete(): Observable<void> {
		return of();
	}
}
