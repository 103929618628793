import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../auth/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { routerLinks } from '@helpers/shared/router-links.const';
import {
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisToasterService
} from '@campaign-portal/components-library';
import { STORAGE } from '@helpers/types/consts';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrl: './profile.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit {
	readonly errors: { key: string; value: string }[] = [
		{key: 'mask', value: 'gl.errors.invalid_format'},
		{key: 'required', value: 'gl.errors.required'}];
	readonly userForm = new FormGroup({
		email: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
		firstName: new FormControl('', { nonNullable: true }),
		lastName: new FormControl('', { nonNullable: true }),
		login: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
		pageSize: new FormControl(25, { nonNullable: true }),
		password: new FormControl<string | null>(null)
	});

	readonly pageSize = [25, 50, 100, 200];
	resetPassword = false;
	firstLoad = true;
	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		private readonly alarisToaster: AlarisToasterService,
		public readonly user: UserService,
		private readonly langService: AlarisLanguageService,
		public readonly profileService: ProfileService,
		private readonly router: Router,
		private readonly storage: AlarisLocalStorageService,
		private readonly cd: ChangeDetectorRef
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.user');
	}

	ngOnInit(): void {
		if ( this.user.isAdmin ) {
			this.userForm.disable();
			this.firstLoad = false;
		} else {
			this.prepareProfile();
		}
	}

	updateProfile(): void {
		this.profileService.updateProfile({
			Email: this.userForm.controls.email.value,
			Details: {
				firstName: this.userForm.controls.firstName.value,
				lastName: this.userForm.controls.lastName.value,
				pageSize: this.userForm.controls.pageSize.value
			},
			Login: this.userForm.controls.login.value,
			Password: this.userForm.controls.password.value
		})
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe((resp) => {
				const message = this.langService.translate('notifications.actions.update');
				if ( resp?.Updated ) {
					this.storage.set(STORAGE.PAGE_SIZE, String(this.userForm.controls.pageSize.value));
					this.userForm.controls.password.setValue(null);
					this.resetPassword = false;
					this.userForm.markAsPristine();
					this.user.register({
						id: this.user.id as number,
						email: resp.Updated.Email,
						login: resp.Updated.Login,
						permissions: resp.Updated.Permissions ?? [],
						details: resp.Updated.Details

					});
					this.alarisToaster.success(message, this.title);
				}
			});
	}

	resetForm(): void {
		this.prepareProfile();
		this.userForm.markAsPristine();
	}

	toUsers(): void {
		this.router.navigate([routerLinks['users'].path]).then();
	}

	private prepareProfile(): void {
		this.profileService.profile()
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe({
				next: (profile) => {
					const user = profile.User;
					this.userForm.patchValue({
						email: user.Email,
						login: user.Login,
						firstName: user.Details.firstName,
						lastName: user.Details.lastName,
						pageSize: user.Details?.['pageSize'] as number ?? 25,
						password: null
					});
					this.userForm.enable();
					this.resetPassword = false;
				},
				complete: () => {
					this.firstLoad = false;
					this.cd.detectChanges();
				}
			});
	}
}
