import { Inject, Injectable } from '@angular/core';
import { AlarisLocalStorageService, TokenStatus } from '@campaign-portal/components-library';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
	AuthIsPasswordCorrectRequest,
	AuthIsPasswordCorrectResponse,
	AuthSignInResponse,
	AuthTokenResponse
} from '@helpers/types/user-and-roles';
import { UserService } from './user.service';
import { SignInLink } from '@helpers/shared/router-links.const';
import { RepoService } from '@helpers/repo/repo.service';
import { STORAGE, TOKEN_REQUEST_TIME } from '@helpers/types/consts';
import { USERS_AND_ROLES_API } from '../app.module';
import { TokenData } from '@helpers/types/models';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly tokenHelper: JwtHelperService;

	constructor(
		private readonly http: HttpClient,
		private readonly router: Router,
		private readonly storage: AlarisLocalStorageService,
		private readonly user: UserService,
		private readonly repo: RepoService,
		@Inject(USERS_AND_ROLES_API) private readonly userAndRolesApi: { url: string }
	) {
		this.tokenHelper = new JwtHelperService();
	}

	get logged(): boolean {
		try {
			const token = this.storage.get(STORAGE.TOKEN);

			const details = this.storage.get(STORAGE.USER_DETAILS);

			if (
				token
				&& this.user.id === null
				&& !this.tokenHelper.isTokenExpired(token)
			) {
				const tokenData: TokenData | null = this.tokenHelper.decodeToken(token);
				if ( tokenData === null ) {
					throw new Error('unable to parse auth token');
				}
				this.user.register({
					email: tokenData.email,
					id: +tokenData.jti,
					login: tokenData.sub,
					permissions: tokenData.permissions?.split(',') || [],
					details: details ? JSON.parse(details) : {}
				});
			}
			return !this.tokenHelper.isTokenExpired(token);
		} catch (e) {
			console.error('Invalid token', this.storage.get(STORAGE.TOKEN), e);
		}
		return false;
	}

	logIn(login: string, password: string): Observable<AuthSignInResponse> {
		this.loading$.next(true);
		return this.http.post<AuthSignInResponse>(
			this.userAndRolesApi.url,
			{
				method: 'Auth.SignIn',
				params: {
					Login: login,
					Password: password
				}
			})
			.pipe(tap((resp) => {
				const result = this.setAuthData(resp);
				this.router.navigate(['']).then();
				return result;
			}));
	}

	logOut(): void {
		this.resetAuth();
		this.router.navigate([SignInLink]);
	}

	isPasswordCorrect(params: AuthIsPasswordCorrectRequest): Observable<AuthIsPasswordCorrectResponse> {
		return this.http.post<AuthIsPasswordCorrectResponse>(
			this.userAndRolesApi.url,
			{
				method: 'Auth.IsPasswordCorrect',
				params
			}
		);
	}

	resetAuth(): void {
		this.storage.remove(STORAGE.TOKEN);
		this.storage.remove(STORAGE.USER_DETAILS);
		this.storage.remove(STORAGE.PAGE_SIZE);
		this.repo.reset();
		this.user.reset();
	}

	refreshToken(): Observable<AuthTokenResponse> {
		return this.http.post<AuthSignInResponse>(
			this.userAndRolesApi.url,
			{
				method: 'Auth.Token'
			}
		).pipe(tap((resp) => this.storage.set(STORAGE.TOKEN, resp.JWT)));
	}

	checkTokenExpired(token: string | null): TokenStatus {
		if ( token === null ) {
			return TokenStatus.EMPTY;
		}
		const tokenData: TokenData | null = this.tokenHelper.decodeToken(token);
		const expired = new Date().getTime() - (tokenData ? tokenData.exp : new Date().getTime()) < TOKEN_REQUEST_TIME;
		return expired ? TokenStatus.EXPIRED : TokenStatus.ACTIVE;
	}

	private setAuthData(resp: AuthSignInResponse): AuthSignInResponse {
		let pageSize = 25;
		if (
			resp.User.Details
			&& resp.User.Details['pageSize']
			&& typeof resp.User.Details['pageSize'] === 'number'
		) {
			pageSize = resp.User.Details['pageSize'] > 0 ? resp.User.Details['pageSize'] : 25;
		}
		if ( resp.JWT ) {
			this.storage.set(STORAGE.PAGE_SIZE, pageSize.toString());
			this.storage.set(STORAGE.TOKEN, resp.JWT);
			this.storage.set(STORAGE.USER_DETAILS, JSON.stringify(resp.User.Details));
		}
		return resp;
	}
}
