import { RouterDataLinks } from '@campaign-portal/components-library';
import { ClientsComponent } from '../../pages/clients/clients.component';
import { RulesComponent } from '../../pages/rules/rules.component';
import { SettingsComponent } from '../../pages/settings/settings.component';
import { SourcesComponent } from '../../pages/sources/sources.component';
import { ProfileComponent } from '../../pages/profile/profile.component';
import { UsersComponent } from '../../pages/users/users.component';

export const SignInLink = 'sign-in';


export const routerLinks: Readonly<RouterDataLinks> = {
	sources: {
		path: 'sources',
		component: SourcesComponent,
		data: {
			permissions: [],
			title: 'sources.title',
			icon: 'show_chart',
			animationState: 'SourcesComponent'
		}
	},
	clients: {
		path: 'clients',
		component: ClientsComponent,
		data: {
			title: 'clients.title',
			icon: 'group',
			animationState: 'ClientsComponent',
			permissions: []
		}
	},
	rules: {
		path: 'rules',
		component: RulesComponent,
		data: {
			permissions: [],
			title: 'rules.title',
			icon: 'rule',
			animationState: 'RulesComponent'
		}
	},
	settings: {
		path: 'settings',
		component: SettingsComponent,
		data: {
			permissions: [],
			title: 'settings.title',
			icon: 'settings',
			animationState: 'SettingsComponent'
		}
	},
	users: {
		// canActivate: [AuthGuard],
		path: 'users',
		component: UsersComponent,
		data: {
			permissions: ['users.read', 'users.edit', 'roles.read', 'roles.edit'],
			title: 'users_roles.title',
			icon: 'manage_accounts',
			animationState: 'UsersComponent'
		}
	}, // only for admin
	profile: {
		path: 'profile',
		component: ProfileComponent,
		data: {
			permissions: [],
			title: 'users_roles.users.profile',
			icon: 'account_circle',
			animationState: 'HlrProfileComponent',
			hiddenMenu: true
		}
	} // account-settings
};
