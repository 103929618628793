import { Pipe, PipeTransform } from '@angular/core';
import { RepoService } from '@helpers/repo/repo.service';
import { Product, Source } from '@helpers/types/models';
import { exist, Id } from '@campaign-portal/namespace/common/id';

@Pipe({
	name: 'product'
})
export class ProductPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | string): Product | null {
		if ( typeof id === 'string' ) {
			id = parseInt(id, 10);
		}
		return this.repo.transformProduct(id) as (Product | null);
	}

}

@Pipe({
	name: 'source'
})
export class SourcePipe implements PipeTransform {

	constructor(
		private repo: RepoService
	) {
	}

	transform(id: Id<exist> | string): Source | null {
		if ( typeof id === 'string' ) {
			id = parseInt(id, 10);
		}
		return this.repo.transformSource(id) as (Source | null);
	}

}