import { inject, Injectable } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class DropAuthGuardService {

	constructor(
		private readonly authService: AuthService
	) {
	}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.authService.resetAuth();
		return true;
	}

}

export const DropAuthGuard: CanActivateFn =
	(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
		return inject(DropAuthGuardService).canActivate();
	};
