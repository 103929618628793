import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisLocalStorageService,
	AlarisTableStateService,
	EditPanelWidth,
	RowActionSimple,
	TABLE_STATE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { Source } from '@helpers/types/models';
import { STORAGE } from '@helpers/types/consts';
import { Paging } from '@campaign-portal/namespace/common/rpc.params';
import { BehaviorSubject } from 'rxjs';
import { TableSettingsService } from '@helpers/api/table-settings.service';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { SourcesService } from './sources.service';
import { SourcesFieldsService } from './sources.fields.service';
import { SourcesDeleteDialogComponent } from './dialog/dialog.component';
import { EditSourceComponent } from './edit-source/edit-source.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-sources',
	templateUrl: './sources.component.html',
	styleUrls: [
		'../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./sources.component.scss'
	],
	providers: [
		AlarisTableStateService,
		{ provide: TABLE_STATE_SERVICE_INJECTOR, useExisting: AlarisTableStateService }
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesComponent extends AlarisComplexTableComponent<Source> implements OnDestroy {

	override readonly rowActions: RowActionSimple<any>[] = [
		{
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		}
	];
	override readonly dblClickRowAction = this.edit;
	override pageSize = parseInt(this.storage.get(STORAGE.PAGE_SIZE) ?? '25', 10);
	override readonly pageSizeOptions = [25, 50, 100, 200];
	override readonly paging: Paging = {
		Skip: this.activePage,
		Take: this.pageSize
	};

	readonly exporting$ = new BehaviorSubject<boolean>(false);

	constructor(
		dataService: SourcesService,
		fieldsService: SourcesFieldsService,
		tableSettingsService: TableSettingsService,
		private readonly storage: AlarisLocalStorageService,
		private readonly dialog: AlarisDialogService,
		public readonly sourcesService: SourcesService
	) {
		super(
			dataService,
			fieldsService,
			tableSettingsService,
			TableTypes.INTERNAL
		);
		this.state.filters
			.set('HuntStop', { enabled: false })
			.set('Ttl', { enabled: false });
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.exporting$.next(false);
		this.exporting$.complete();
	}

	edit(source?: Source): void {
		this.editPanel.open<EditSourceComponent, {
			source: Source
		}, boolean>(EditSourceComponent, EditPanelWidth.SM, { source })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	delete(source: Source): void {
		this.dialog.open<boolean>(SourcesDeleteDialogComponent, { autoFocus: false, data: { source } })
			.closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	export(): void {
		this.exporting$.next(true);
		this.sourcesService.export(this.total, this.state.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.exporting$.next(false);
			});
	}

}
