import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, finalize, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { USERS_AND_ROLES_API } from '../../app.module';

@Injectable({
	providedIn: 'root'
})
export class UsersAndRolesService {


	constructor(
		@Inject(USERS_AND_ROLES_API) public readonly userAndRolesApi: { url: string },
		private readonly http: HttpClient
	) {
	}

	loader<Result>(
		method: unknown,
		params?: unknown,
		loading$?: BehaviorSubject<boolean>,
		callback?: (response: Result) => unknown
	): Observable<Result> {
		loading$?.next(true);
		return this.http.post<Result>(
			this.userAndRolesApi.url,
			{
				method,
				params
			}
		).pipe(
			take(1),
			map((result) => {
				loading$?.next(false);
				if ( callback ) {
					callback(result);
				}
				return result;
			}),
			finalize(() => {
				if ( loading$?.getValue() ) {
					loading$?.next(false);
				}
			})
		);
	}
}
