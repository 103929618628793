import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlarisConfigService } from '@campaign-portal/components-library';
import { AuthService } from '../../auth/auth.service';
import { AutofillEvent } from '@angular/cdk/text-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputType } from '@campaign-portal/namespace/common/entityField';

interface SignInForm {
	login: FormControl<string>;
	password: FormControl<string>;
}

@Component({
	selector: 'app-sign-in-layout',
	templateUrl: './sign-in-layout.component.html',
	styleUrl: './sign-in-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInLayoutComponent {

	readonly InputType = InputType;

	readonly loading$ = new BehaviorSubject<boolean>(false);


	readonly signInForm = new FormGroup<SignInForm>({
		login: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
		password: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
	});


	private readonly destroyRef = inject(DestroyRef);

	constructor(
		public readonly config: AlarisConfigService,
		private readonly authService: AuthService
	) {
	}

	updateValidators(event: AutofillEvent, control: FormControl<string>): void {
		if ( event.isAutofilled ) {
			control.removeValidators(Validators.required);
		} else {
			control.addValidators(Validators.required);
		}
		control.updateValueAndValidity();
	}

	signIn(): void {
		if ( this.signInForm.valid ) {
			this.loading$.next(true);
			this.authService.logIn(
				this.signInForm.controls.login.value,
				this.signInForm.controls.password.value
			)
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe({
					next: () => {
						this.loading$.next(false);
					},
					error: () => {
						this.loading$.next(false);
					}
				});
		}
	}

}
