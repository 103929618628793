import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Client } from '@helpers/types/models';
import { exist } from '@campaign-portal/namespace/common/id';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClientsService } from '../clients.service';

@Component({
	selector: 'app-clients-delete-dialog',
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsDeleteDialogComponent {

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(DIALOG_DATA) public readonly data: { client: Client<exist> },
		public readonly clientsService: ClientsService,
		private readonly dialogRef: DialogRef<boolean>
	) {
	}

	confirm(): void {
		this.clientsService.delete({ id: this.data.client.id })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if ( resp.deleted ) {
					this.dialogRef.close(true);
				}
			});
	}

	close(): void {
		this.dialogRef.close(false);
	}
}
