@if (showImg) {
	<div [class]="type" class="img"></div>
}
<div class="title">{{ title | translate }}</div>
<div class="sub-title">{{ subTitle | translate }}</div>
<div class="buttons">
	@if (buttonText) {
		<button (click)="clicked()" [disabled]="disabled" alaris-button bType="primary" size="md">
			<alaris-icon [name]="icon" class="in-primary"></alaris-icon>
			{{ buttonText | translate }}
		</button>
	}
	@if (optionalButtonText) {
		<button (click)="optionalButtonClicked()"
				[disabled]="disabled" alaris-button bType="outline" size="md">
			{{ optionalButtonText | translate }}
		</button>
	}
</div>

