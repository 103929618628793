import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {
	AlarisLanguageService,
	AlarisLocalStorageService,
	China,
	English,
	Italy,
	Russian,
	Spain
} from '@campaign-portal/components-library';
import { RouterOutlet } from '@angular/router';
import { STORAGE } from '@helpers/types/consts';
import { languageConfig } from './app.module';

const animationTiming = '1s ease-out';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	animations: [
		trigger('layoutChanged', [
			transition('SignInLayoutComponent <=> MainLayoutComponent', [
				style({ height: 0, opacity: 0 }),
				animate(animationTiming, style({ height: '*', opacity: 1 }))
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

	constructor(
		private readonly lang: AlarisLanguageService,
		private readonly storage: AlarisLocalStorageService
	) {
	}

	ngOnInit(): void {
		if ( window.frames?.frameInVoice?.Globals ) {
			const globals = window.frames?.frameInVoice?.Globals;
			if ( globals.token ) {
				this.storage.set(STORAGE.TOKEN, window.frames.frameInVoice.Globals.token);
			}
			if ( globals.currentLang ) {
				let currentLang = English.id;
				switch (globals.currentLang) {
					case 'ZHO':
						currentLang = China.id;
						break;
					case 'ITA':
						currentLang = Italy.id;
						break;
					case 'ESP':
						currentLang = Spain.id;
						break;
					case 'RUS':
						currentLang = Russian.id;
						break;
					case 'ENG':
					default:
						currentLang = English.id;
				}
				//
				const lang = languageConfig.languages.find((language) => {
					return currentLang === language.id;
				});
				if ( lang ) {
					this.storage.set(STORAGE.LANGUAGE, lang.id);
				}
			}
		}
		this.lang.restore();
	}

	prepareRoute(outlet: RouterOutlet): boolean {
		return outlet &&
			outlet.activatedRouteData &&
			outlet.activatedRouteData['animationState'];
	}

}
