import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import {
	UsersProfileResponse,
	UsersUpdateProfileRequest,
	UsersUpdateProfileResponse
} from '@helpers/types/user-and-roles';
import { UsersAndRolesService } from '@helpers/api/users-and-roles.service';
import { AlarisDialogService } from '@campaign-portal/components-library';
import { PasswordDialogComponent } from './password-dialog/password-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {

	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly uRService: UsersAndRolesService,
		private readonly dialog: AlarisDialogService
	) {
	}

	profile(): Observable<UsersProfileResponse> {
		return this.uRService.loader('Users.Profile', {}, this.loading$);
	}

	updateProfile(params: UsersUpdateProfileRequest): Observable<UsersUpdateProfileResponse | null> {
		const request = this.uRService.loader<UsersUpdateProfileResponse>('Users.UpdateProfile', params, this.loading$);
		if ( params.Password !== null ) {
			return this.dialog.open<boolean>(PasswordDialogComponent, {
				data: { login: params.Login },
				autoFocus: false
			})
				.closed
				.pipe(switchMap((resp) => {
					if ( resp ) {
						return request;
					} else {
						this.loading$.next(false);
						return of(null);
					}
				}));
		}
		return request;
	}

}