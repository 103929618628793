<alaris-card [alarisFade]="'vertical'" [alarisLoading]="profileService.loading$">
	<h6>{{ 'users_roles.users.personal_information' | translate }}</h6>
	<form (ngSubmit)="updateProfile()" [formGroup]="userForm" class="formGroup">
		<alaris-input [placeholder]="'users_roles.users.first_name' | translate"
					  [readonly]="userForm.disabled"
					  formControlName="firstName">
			{{ 'users_roles.users.first_name' | translate }}
		</alaris-input>

		<alaris-input [placeholder]="'users_roles.users.last_name' | translate"
					  [readonly]="userForm.disabled"
					  formControlName="lastName">
			{{ 'users_roles.users.last_name' | translate }}
		</alaris-input>

		<alaris-input [placeholder]="'users_roles.users.email' | translate"
					  [readonly]="userForm.disabled"
					  formControlName="email"
					  [errors]="errors">
			{{ 'users_roles.users.email' | translate }}
		</alaris-input>

		<alaris-input [placeholder]="'users_roles.users.login' | translate"
					  [readonly]="userForm.disabled"
					  formControlName="login"
					  [errors]="errors">
			{{ 'users_roles.users.login' | translate }}
		</alaris-input>

		<alaris-select2 [label]="'gl.page_size' | translate"
						[readonly]="userForm.disabled"
						formControlName="pageSize"
						[errors]="errors">
			@for (size of pageSize; track size) {
			<alaris-options2 [value]="size">
			{{ size }}
			</alaris-options2>
			}
		</alaris-select2>
		@if (user.isAdmin) {
		<alaris-alert type="info">{{ 'users_roles.users.admin_note' | translate }}</alaris-alert>
				<button alaris-button (click)="toUsers()">
					<alaris-icon name="manage_accounts"></alaris-icon>
		{{ 'users_roles.title' | translate }}
		</button>
		} @else {
				<div class="title">{{ 'users_roles.users.password' |translate }}</div>
				<div class="note">{{ 'users_roles.users.password_note' |translate }}</div>
				@if (!resetPassword) {
		<button (click)="resetPassword = true"
				type="button"
				alarisButton
				bType="outline"
				size="lg">
			{{ 'users_roles.users.password_change' | translate }}
		</button>
		} @else {
		<div class="custom-fields">
			<div class="reset-password">
				<alaris-input [autofocus]="true" formControlName="password"
							  type="password"
							  class="custom-field">
					{{ 'users_roles.users.new_password' | translate }}
				</alaris-input>
			</div>
		</div>
		}
		<hr>
		<div class="in-row">
			<button [disabled]="!userForm.dirty || userForm.invalid" alaris-button bType="primary"
					type="submit">
				<alaris-icon name="icon-success"></alaris-icon>
		{{ 'actions.save_changes' | translate }}
		</button>
		<button (click)="resetForm()" alaris-button bType="outline" type="button">
		{{ 'actions.cancel' | translate }}
		</button>
	</div>

		}
	</form>
</alaris-card>
