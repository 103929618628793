import { Injectable } from '@angular/core';
import { AbstractCRUDService } from '@campaign-portal/components-library';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';

@Injectable({
	providedIn: 'root'
})
export class SourcesFieldsService implements AbstractCRUDService {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly name: EntityField = {
		id: 0,
		name: 'sources.name',
		variable: 'Name',
		type: InputType.TEXT,
		filterType: FilterType.LIKE,
		required: true
	};
	readonly type: EntityField = {
		id: 1,
		name: 'sources.type',
		variable: 'Type',
		type: InputType.TEXT,
		filterType: FilterType.LIKE,
		required: true
	};
	readonly huntStop: EntityField = {
		id: 2,
		name: 'sources.huntStop',
		variable: 'HuntStop',
		type: InputType.TEXT,
		filterType: FilterType.EXACT,
		required: false
	};
	readonly ttl: EntityField = {
		id: 3,
		name: 'sources.ttl',
		variable: 'Ttl',
		type: InputComplexType.RANGE,
		filterType: FilterType.BETWEEN,
		required: false
	};

	readonly headers: EntityField[] = [
		this.name,
		this.type,
		this.huntStop,
		this.ttl
	];


	readonly create = this.update;

	constructor() {
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}
}
