import { Injectable } from '@angular/core';
import { AlarisApiService, ExtendableRefBookService } from '@campaign-portal/components-library';
import { Product, ProductsType } from '@helpers/types/models';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { ProductsReadRequest, ProductsReadResponse } from '@helpers/types/hlr';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RulesFieldsService } from '../../pages/rules/rules.fields.service';

@Injectable({
	providedIn: 'root'
})
export class ProductsService extends ExtendableRefBookService<Product> {
	readonly clientProducts$ = new BehaviorSubject<Product[]>([]);

	constructor(
		private readonly api: AlarisApiService,
		public readonly fieldsService: RulesFieldsService
	) {
		super();
	}

	get clientProducts(): Product[] {
		return this.clientProducts$.getValue();
	}

	loadProducts(productsTypes: ProductsType[]): Observable<ReadResponse<Product[]>> {
		const params: ProductsReadRequest = {
			ProductsTypes: productsTypes
		};
		let request = this.api.loader<ProductsReadResponse>('Products.Read', params, this.loading$);
		if ( window.frames?.REPO?.product ) {
			request = of({
				data: window.frames?.REPO?.product,
				length: window.frames?.REPO?.product.length
			});
		}

		return request
			.pipe(
				map((resp) => {
					this.loading$?.next(false);
					return {
						Success: true,
						Total: resp.length,
						Data: resp.data
					};
				}),
				map((resp) => {
					return this.processProducts(resp, productsTypes);
				})
			);
	}

	processProducts(resp: ReadResponse<Product[]>, productsTypes: ProductsType[]): ReadResponse<Product[]> {
		this.clientProducts$.next([]);
		const clientProducts: Product[] = [];
		this.list$.next(resp.Data);
		this.map$.next(this.list.reduce((result, item) => {
			if (item.product_direction === 0) {
				if ( window.frames?.REPO?.product ) {
					if (productsTypes.length > 1 && (item.product_type === 3 || item.product_type === 7)) {
						clientProducts.push(item);
					}
					if (productsTypes.length === 1 && item.product_type === 7) {
						clientProducts.push(item);
					}
				} else {
					clientProducts.push(item);
				}
			}
			result.set(item.product_id, item);
			return result;
		}, new Map()));
		this.clientProducts$.next(clientProducts);
		return resp;
	}

	override clear(): void {
		super.clear();
		this.clientProducts$.next([]);
	}

}
