import { Injectable } from '@angular/core';
import { AlarisApiService, ExtendableRefBookService } from '@campaign-portal/components-library';
import { Provider } from '@helpers/types/models';
import { Observable } from 'rxjs';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { ProvidersReadResponse } from '@helpers/types/hlr';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ProvidersService extends ExtendableRefBookService<Provider> {

	constructor(
		private readonly api: AlarisApiService
	) {
		super();
	}

	override load(): Observable<ReadResponse<Provider[]>> {
		return this.api.loader<ProvidersReadResponse>('Providers.Read', {}, this.loading$)
			.pipe(
				map((resp) => {
					return {
						Success: !!resp.data,
						Total: resp.length,
						Data: resp.data
					};
				}),
				map((resp) => {
					return this.process(resp);
				})
			);
	}

	override process(resp: ReadResponse<Provider[]>): ReadResponse<Provider[]> {
		this.list$.next(resp.Data.sort((a, b) => a.Name.localeCompare(b.Name)));
		this.map$.next(this.list.reduce((result, item) => {
			result.set(item.Name, item);
			return result;
		}, new Map()));
		return resp;
	}
}
