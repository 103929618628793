import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { Provider, ProviderField, Source } from '@helpers/types/models';
import {
	AlarisEditPanelService,
	AlarisLanguageService,
	EditPanelInputData,
	TableEntityField
} from '@campaign-portal/components-library';
import { SourcesService } from '../sources.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ProvidersService } from '../providers.service';
import { Id } from '@campaign-portal/namespace/common/id';
import { AdditionalValidators } from '@helpers/shared/validators';
import { LocalAddressesService } from '@helpers/repo/local-addresses.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Component({
	selector: 'app-edit-source',
	templateUrl: './edit-source.component.html',
	styleUrl: './edit-source.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSourceComponent implements OnInit {
	readonly errors: { key: string; value: string }[] = [
		{key: 'mask', value: 'gl.errors.invalid_format'},
		{key: 'required', value: 'gl.errors.required'}];
	source: Source;
	readonly default: Source = {
		SourceId: null,
		Name: '',
		Type: '',
		Url: '',
		CacheSuccessfulResponseOnly: 0,
		ForceRerouteKeepingMccmnc: false,
		UseErrorCode: false,
		UseStatusId: false,
		FixedMccMnc: false,
		Ttl: 0,
		StatusRequestUrl: '',
		HuntStop: false,
		Username: '',
		Password: '',
		RequestTimeout: 0,
		Email: '',
		CustomerId: '',
		ServiceCentreAddress: '',
		SourceGt: '',
		DestinationGt: '',
		VerifiedSmsAgentId: '',
		VerifiedSmsServiceAccountPath: '',
		VerifiedSmsPrivateKeyPath: '',
		Domain: '',
		Ip: '',
		Port: null,
		SenderAddress: '',
		SenderPort: null,
		MccmncTranslation: null,
		MccmncTranslationBy: null,
		MccmncTranslationBy2: null,
		MccSet: null,
		ResponseCodeCacheList: null,
		IgnoreWireline: false,
		InvNumberCode: null,
		HlrResponseCodeFrom: '',
		PresenceMccmnc: '',
		Retries: null,
		ErrorCodeTranslation: null,
		ApiKey: '',
		CustomerKey: '',
		SecretKey: '',
		ForceErrCodeTranslation: false,
		StatusRequestDelay: null
	};
	readonly sourceForm = new FormGroup({
		SourceId: new FormControl<Id>(null),
		Name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		Type: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		Ttl: new FormControl<number | null>(
			null,
			[Validators.pattern('^\\d+$'), Validators.max(1000000)]
		),
		Url: new FormControl<string>(
			'',
			{
				nonNullable: true,
				validators: Validators.pattern(AdditionalValidators.urlPattern)
			}
		),
		StatusRequestUrl: new FormControl<string>('', {
			nonNullable: true,
			validators: Validators.pattern(AdditionalValidators.urlPattern)
		}),
		HuntStop: new FormControl<boolean | null>(null),
		Username: new FormControl<string>('', { nonNullable: true }),
		Password: new FormControl<string>('', { nonNullable: true }),
		RequestTimeout: new FormControl<number | null>(null,
			[Validators.pattern('^\\d+$'), Validators.min(0), Validators.max(1000000)]),
		Email: new FormControl<string>('', { nonNullable: true }),
		CustomerId: new FormControl<string>('', { nonNullable: true }),
		SourceGt: new FormControl<string>('', { nonNullable: true }),
		DestinationGt: new FormControl<string>('', { nonNullable: true }),
		ServiceCentreAddress: new FormControl<string>('', { nonNullable: true }),
		VerifiedSmsAgentId: new FormControl<string>('', { nonNullable: true }),
		VerifiedSmsServiceAccountPath: new FormControl<string>('', { nonNullable: true }),
		VerifiedSmsPrivateKeyPath: new FormControl<string>('', { nonNullable: true }),
		Domain: new FormControl<string>('', { nonNullable: true }),
		Ip: new FormControl<string>('', {
			nonNullable: true,
			validators: Validators.pattern(AdditionalValidators.ipPattern)
		}),
		Port: new FormControl<number | null>(null,
			[Validators.pattern('^\\d+$'), Validators.min(0), Validators.max(65535)]),
		MccmncTranslation: new FormControl<string | null>(null, AdditionalValidators.checkJson),
		MccmncTranslationBy: new FormControl<string | null>(null, AdditionalValidators.checkJson),
		MccmncTranslationBy2: new FormControl<string | null>(null, AdditionalValidators.checkJson),
		MccSet: new FormControl<string | null>(null, AdditionalValidators.checkArrayThreeNumbers),
		ResponseCodeCacheList: new FormControl<string | null>(null, AdditionalValidators.checkArray),
		ErrorCodeTranslation: new FormControl<string | null>(null, AdditionalValidators.checkJson),
		ApiKey: new FormControl<string>('', { nonNullable: true }),
		CustomerKey: new FormControl<string>('', { nonNullable: true }),
		SecretKey: new FormControl<string>('', { nonNullable: true }),
		ForceErrCodeTranslation: new FormControl<boolean | null>(null),
		Retries: new FormControl<number | null>(null, [Validators.pattern('^\\d+$'), Validators.min(0)]),
		ForceRerouteKeepingMccmnc: new FormControl<boolean | null>(null),
		CacheSuccessfulResponseOnly: new FormControl<number | null>(null, [Validators.min(0), Validators.max(2)]),
		UseErrorCode: new FormControl<boolean | null>(null),
		UseStatusId: new FormControl<boolean | null>(null),
		FixedMccMnc: new FormControl<boolean | null>(null),
		InvNumberCode: new FormControl<number | null>(null),
		HlrResponseCodeFrom: new FormControl<string>('', { nonNullable: true }),
		PresenceMccmnc: new FormControl<string>('', { nonNullable: true }),
		SenderAddress: new FormControl<string>(
			'',
			{
				nonNullable: true,
				validators: [Validators.pattern(AdditionalValidators.urlPattern), this.validateSenderAddress()]
			}
		),
		SenderPort: new FormControl<number | null>(null,
			[Validators.pattern('^\\d+$'), Validators.min(0), Validators.max(65535)]),
		IgnoreWireline: new FormControl<boolean | null>(null),
		StatusRequestDelay: new FormControl<number | null>(null, [Validators.pattern('^(0|[1-9]\\d*)$')])
	});

	providers: Provider[] = [];
	readonly providersFilter = new FormControl();

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: { source?: Source },
		public readonly sourcesService: SourcesService,
		public readonly providersService: ProvidersService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly localAddresses: LocalAddressesService,
		private readonly lService: AlarisLanguageService,
	) {
		if ( this.inputData.source ) {
			this.source = this.inputData.source;
		} else {
			this.source = structuredClone(this.default);
		}
	}

	ngOnInit(): void {
		this.reset();
		this.sourceForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				if (this.sourceForm.controls.Type.value === 'infobip') {
					const applyValidators = (control: FormControl, isRequired: boolean): void => {
						if (isRequired) {
							control.addValidators(Validators.required);
							control.setErrors({ 'required': true });
						}
					};

					const updateControlsState = (): void => {
						const isApiKeyDisabled
							= !!this.sourceForm.controls.Username.value || !!this.sourceForm.controls.Password.value;
						const areUsernameAndPasswordDisabled
							= !!this.sourceForm.controls.ApiKey.value;

						isApiKeyDisabled ? this.sourceForm.controls.ApiKey.disable({onlySelf: true})
							: this.sourceForm.controls.ApiKey.enable({onlySelf: true});
						areUsernameAndPasswordDisabled ? this.sourceForm.controls.Username.disable({onlySelf: true})
							: this.sourceForm.controls.Username.enable({onlySelf: true});
						areUsernameAndPasswordDisabled ? this.sourceForm.controls.Password.disable({onlySelf: true})
							: this.sourceForm.controls.Password.enable({onlySelf: true});

						applyValidators(this.sourceForm.controls.ApiKey,
							!isApiKeyDisabled && !areUsernameAndPasswordDisabled);
						applyValidators(this.sourceForm.controls.Username,
							!isApiKeyDisabled && !areUsernameAndPasswordDisabled);
						applyValidators(this.sourceForm.controls.Password,
							!isApiKeyDisabled && !areUsernameAndPasswordDisabled);
						applyValidators(this.sourceForm.controls.Username,
							isApiKeyDisabled && !this.sourceForm.controls.Username.value);
						applyValidators(this.sourceForm.controls.Password,
							isApiKeyDisabled && !this.sourceForm.controls.Password.value);
					};
					updateControlsState();
				}
			});

		this.providersService.load()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() =>{
				this.applyValidatorsPerType(this.sourceForm.controls.Type.value)
			});
		this.providersService.list$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((list) => {
				this.providersFilter.reset();
				this.providers = list;
			});
		this.providersFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.providers = this.providersService.list
					.filter(item => item.Name.toLowerCase().includes(value?.toLowerCase()));
			});

		this.sourceForm.controls.Type.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((type)=> {
				const defaultValues = structuredClone(this.default);
				for ( const controlsKey in this.sourceForm.controls ) {
					this.sourceForm.get(controlsKey)?.clearValidators();
				}
				this.sourceForm.reset({
					...defaultValues,
					SourceId: this.sourceForm.controls.SourceId.value ?? this.source.SourceId,
					Name: this.sourceForm.controls.Name.value ?? this.source.Name,
					Type: type
				}, { emitEvent: false });
				this.applyValidatorsPerType(type);
			});
	}


	close(): void {
		this.editPanel.close(false);
	}

	reset(): void {
		const source = structuredClone(this.source);
		this.sourceForm.reset(source);
	}

	save(): void {
		this.sourcesService.update(this.sourceForm.getRawValue())
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if (
					resp.data?.source_id
				) {
					this.editPanel.close(true);
				}
			});

	}

	getControl(field: ProviderField): FormControl {
		const fieldControl = this.sourceForm.get(field.field_name) as FormControl | null;
		if (fieldControl?.value && typeof fieldControl.value === "object") {
			const objectVal = JSON.stringify(fieldControl.value);
			fieldControl.setValue(objectVal);
		}
		return fieldControl || new FormControl();
	}

	getField(field: ProviderField, i: number): TableEntityField {
		let filterType;
		let type;
		switch (field.field_name) {
			case 'HuntStop':
				filterType = FilterType.EXACT;
				type = InputComplexType.CHECKBOX;
				break;
			case 'ForceErrCodeTranslation':
				filterType = FilterType.EXACT;
				type = InputComplexType.CHECKBOX;
				break;
			case 'ForceRerouteKeepingMccmnc':
				filterType = FilterType.EXACT;
				type = InputComplexType.CHECKBOX;
				break;
			case 'CacheSuccessfulResponseOnly':
				filterType = FilterType.EXACT;
				type = InputType.NUMBER;
				break;
			case 'UseErrorCode':
				filterType = FilterType.EXACT;
				type = InputComplexType.CHECKBOX;
				break;
			case 'UseStatusId':
				filterType = FilterType.EXACT;
				type = InputComplexType.CHECKBOX;
				break;
			case 'FixedMccMnc':
				filterType = FilterType.EXACT;
				type = InputComplexType.CHECKBOX;
				break;
			case 'IgnoreWireline':
				filterType = FilterType.EXACT;
				type = InputComplexType.CHECKBOX;
				break;
			case 'Ttl':
				filterType = FilterType.EXACT;
				type = InputType.NUMBER;
				break;
			case 'RequestTimeout':
			case 'Port':
				filterType = FilterType.EXACT;
				type = InputType.NUMBER;
				break;
			case 'Retries':
				filterType = FilterType.EXACT;
				type = InputType.NUMBER;
				break;
			case 'InvNumberCode':
			case 'SenderPort':
				filterType = FilterType.EXACT;
				type = InputType.NUMBER;
				break;
			case 'StatusRequestDelay':
				filterType = FilterType.EXACT;
				type = InputType.NUMBER;
				break;
			default:
				filterType = FilterType.LIKE;
				type = InputType.TEXT;
		}
		const val: TableEntityField =
			new TableEntityField({
				id: i,
				data: [],
				name: 'sources.fields.' + field.cfg_field_name,
				variable: field.field_name,
				required: field.mandatory,
				type,
				filterType,
				property: '',
				readonly: false,
			});
		return val;
	}

	private validateSenderAddress(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if ( !control.value ) {
				return null;
			}
			return this.localAddresses.map.get(control.value)
				? { customErr: { value: control.value } }
				: null;
		};
	}

	private applyValidatorsPerType(value: string): void {
		const fields = this.providersService.map.get(value)?.Fields ?? [];
		const validatorsMap: { [key: string]: ValidatorFn[] } = {
			'ErrorCodeTranslation': [AdditionalValidators.checkJson],
			'Ip': [Validators.pattern(AdditionalValidators.ipPattern)],
			'MccmncTranslation': [AdditionalValidators.checkJson],
			'MccmncTranslationBy': [AdditionalValidators.checkJson],
			'MccmncTranslationBy2': [AdditionalValidators.checkJson],
			'MccSet': [AdditionalValidators.checkArrayThreeNumbers],
			'CacheSuccessfulResponseOnly': [Validators.min(0), Validators.max(2)],
			'ResponseCodeCacheList': [AdditionalValidators.checkArray],
			'Port': [
				Validators.pattern('^\\d+$'),
				Validators.min(0),
				Validators.max(65535)
			],
			'Retries': [
				Validators.pattern('^\\d+$'),
				Validators.min(0)
			],
			'RequestTimeout': [
				Validators.pattern('^\\d+$'),
				Validators.min(0),
				Validators.max(1000000)
			],
			'SenderAddress': [
				Validators.pattern(AdditionalValidators.urlPattern),
				this.validateSenderAddress()
			],
			'SenderPort': [
				Validators.pattern('^\\d+$'),
				Validators.min(0),
				Validators.max(65535)
			],
			'StatusRequestDelay': [Validators.pattern('^(0|[1-9]\\d*)$')],
			'StatusRequestUrl': [Validators.pattern(AdditionalValidators.urlPattern)],
			'Ttl': [
				Validators.pattern('^\\d+$'),
				Validators.max(1000000)
			],
			'Url': [Validators.pattern(AdditionalValidators.urlPattern)]
		};
		fields.forEach((field) => {
			const formControl = this.sourceForm.get(field.field_name);
			if (formControl) {
				const validators = validatorsMap[field.field_name] || [];
				formControl.setValidators(validators);
				if (field.mandatory) {
					formControl.addValidators(Validators.required);
				}
				formControl.updateValueAndValidity();
			} else {
				console.warn(`Field ${field.field_name} not found.`);
			}
		});
	}

	getExample(field: ProviderField): string {
		const syntax = this.lService.translate('sources.syntax');
		const examples: { [key: string]: string } = {
			'Translate error code to MCCMNC': syntax + '{"0":"238002", "r1": "999999"}',
			'MCC list to handle IMSI from response': syntax + '[262,302,352,354,467,503,751]',
			'MCCMNC translation': syntax + '{"238001":"238002", "238003": "999999"}',
			'MCCMNC translation map': syntax + '{"724301":18, "724302":54}',
			'MCCMNC translation map extra': syntax + '{"724301":18, "724302":54}',
			'API key': this.lService.translate('sources.tooltip_credentials'),
			'User email': this.lService.translate('sources.tooltip_credentials'),
			'Customer ID': this.lService.translate('sources.tooltip_credentials'),
			'Customer key (username)': this.lService.translate('sources.tooltip_credentials'),
			'Secret key (password)': this.lService.translate('sources.tooltip_credentials'),
			'Source URL': this.lService.translate('sources.tooltip_URL')
				+ syntax + 'https://domain/api/method, http://1.2.3.4/api/method',
			'Status URL': this.lService.translate('sources.tooltip_URL')
				+ syntax + 'https://domain/api/method, http://1.2.3.4/api/method',
			'URL for first request to provider': this.lService.translate('sources.tooltip_URL')
				+ syntax + 'https://domain/api/method, http://1.2.3.4/api/method',
			'Translate OCN to MCCMNC': syntax + '{"8304":"302002", "8202":"302003"}',
			'Mapping carrier_id from response to MCCMNC':
				syntax + '{"1" : "440050", "2" : "440020", "3" : "440010", "6" : "440011"}',
			'Translate network name to MCCMNC': syntax + '{"Algar Telecom" : "724032", "Claro" : "724005"}',
			'Translate MNP code to MCCMNC': syntax + '{"321" : "724005", "341" : "724002", "301" : "724018"}'
		};

		return examples[field.web_description] || '';
	}
}
