import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
	HlrSettingsCreateResponse,
	HlrSettingsExportConfigResponse,
	HlrSettingsImportConfigResponse,
	HlrSettingsReadResponse,
	HlrSettingsUpdateResponse
} from '@helpers/types/hlr';
import { AbstractCRUDService, AlarisApiService } from '@campaign-portal/components-library';
import { HlrSettings } from '@helpers/types/models';
import { exist } from '@campaign-portal/namespace/common/id';

@Injectable({
	providedIn: 'root'
})
export class SettingsService implements AbstractCRUDService {


	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService
	) {
	}

	read(): Observable<HlrSettingsReadResponse<exist>> {
		return this.api.loader<HlrSettingsReadResponse<exist>>('HlrSettings.Read', {}, this.loading$);
	}

	update(params: HlrSettings): Observable<HlrSettingsUpdateResponse | HlrSettingsCreateResponse> {
		const method = params.settingId ? 'HlrSettings.Update' : 'HlrSettings.Create';
		return this.api.loader<HlrSettingsUpdateResponse | HlrSettingsCreateResponse>(method, params, this.loading$);
	}

	importConfig(): Observable<HlrSettingsImportConfigResponse> {
		return this.api.loader<HlrSettingsImportConfigResponse>('HlrSettings.ImportConfig', {}, this.loading$);
	}

	exportConfig(): Observable<HlrSettingsExportConfigResponse> {
		return this.api.loader<HlrSettingsExportConfigResponse>('HlrSettings.ExportConfig', {}, this.loading$);
	}

	delete(): Observable<void> {
		return of();
	}
}
