<alaris-card>
	@if (tRows.length > 0 || tRows.length === 0 && state.isFiltersApplied) {
		<div class="table-management">
			<div class="left">
				<span class="total"> {{ totalTitle | translate }}: {{ total }} </span>
			</div>
			<div class="right">
				<button (click)="refresh()" alaris-button bType="outline" size="lg">
					<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
					{{ 'actions.refresh_page' | translate }}
				</button>
				<button (click)="export()" [disabled]="!!(exporting$ | async)" alaris-button bType="outline" size="lg">
					@if (exporting$ | async) {
						<alaris-spinner></alaris-spinner>
					} @else {
						<alaris-icon name="icon-export"></alaris-icon>
					}
					{{ 'gl.export' | translate }}
				</button>
				<button (click)="edit()" alaris-button bType="primary" size="lg">
					<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
					{{ 'users_roles.users.create' | translate }}
				</button>
			</div>
		</div>
	}
	<div [alarisLoading]="loading$" alarisHorizontalOverflow class="content">
		@if (tRows.length === 0 && !state.isFiltersApplied) {
			<app-empty-table type="users" (newUser)="edit()"></app-empty-table>
		} @else {
			<alaris-table [dblClickRowAction]="dblClickRowAction.bind(this)"
						  [dropDownRowActions]="dropDownRowActions"
						  [rowActions]="rowActions"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{Details}"
						  [resizable]="resizable"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [class.without-table-bar]="selection !== null && selection.length === 0"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #Details [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.Details?.firstName }} {{ data.Details?.lastName }}
</ng-template>
