import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routerLinks, SignInLink } from '@helpers/shared/router-links.const';
import { GoHomeComponent } from './layouts/go-home.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SignInLayoutComponent } from './layouts/sign-in-layout/sign-in-layout.component';
import { DropAuthGuard } from './auth/drop-auth.guard';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
	{
		path: SignInLink,
		component: SignInLayoutComponent,
		canActivate: [DropAuthGuard],
		data: { animationState: 'SignInLayoutComponent' }
	},
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		data: { animationState: 'MainLayoutComponent' },
		children: [
			{
				path: '',
				component: GoHomeComponent
			},
			...Object.values(routerLinks)
		]
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	declarations: [],
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {
}

