import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { concatMap, from, Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AlarisConfigService, AlarisLocalStorageService, TokenStatus } from '@campaign-portal/components-library';
import { USERS_AND_ROLES_API } from '../../app.module';
import { STORAGE } from '@helpers/types/consts';

@Injectable()
export class RpcInterceptor implements HttpInterceptor {
	private reqID = 1;

	constructor(
		private readonly config: AlarisConfigService,
		private readonly storage: AlarisLocalStorageService,
		private readonly auth: AuthService,
		@Inject(USERS_AND_ROLES_API) private readonly userAndRolesApi: { url: string }
	) {
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.body && req.url === (this.config.api + '?' + req.body?.method)) {
			req.body.jsonrpc = '2.0';
			req.body.id = this.reqID++;
			if ( !req.body.params ) {
				req.body.params = {};
			}
		}

		if ( req.url !== this.config.api && req.url !== this.userAndRolesApi.url ) {
			return next.handle(req);
		}

		if ( req.body instanceof FormData ) {
			// file uploading
			req = req.clone({ url: req.url + '?' + req.body.get('method') });
			req.body.append('id', this.reqID++);
		} else {
			req = req.clone({ url: req.url + '?' + req.body.method });
			req.body.jsonrpc = '2.0';
			req.body.id = this.reqID++;
			if ( !req.body.params ) {
				req.body.params = {};
			}
		}

		if ( this.auth.logged ) {
			const authReq = req.clone({
				headers: req.headers.set('Authorization', `Bearer ${this.storage.get(STORAGE.TOKEN)}`)
			});
			return next.handle(authReq);
		} else if (
			this.auth.checkTokenExpired(this.storage.get(STORAGE.TOKEN)) === TokenStatus.EXPIRED
		) {
			this.storage.remove(STORAGE.TOKEN);
			return from(this.auth.refreshToken()).pipe(
				concatMap(() => {
					const authReq = req.clone({
						headers: req.headers.set('Authorization', `Bearer ${this.storage.get(STORAGE.TOKEN)}`)
					});
					return next.handle(authReq);
				})
			);
		}
		return next.handle(req);
	}
}
