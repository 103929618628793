<alaris-menu-sidebar [menuList]="menuList" [showLogo]="config.env.showLogo ?? true"
					 [showTitle]="config.env.showTitle ?? true">
</alaris-menu-sidebar>

<alaris-root-content [fade]="(this.authGuard.currentRouteData | async)?.['overflow'] || false">
	<alaris-navbar [backRoutePath]="(authGuard.parentRoute | async)?.['url'] || []"
				   [backRoute]="(authGuard.currentRouteData | async)?.['backRoute']"
				   [title]="((authGuard.currentRouteData | async)?.['title'] || dynamicTitle) | translate ">
		@if (!globals) {
			<alaris-profile (logOutAttempt)="auth.logOut()"></alaris-profile>
		}
	</alaris-navbar>


	<div [@mainLayoutChanged]="prepareInterfaceRoute(outlet)"
		 [class.overflow]="(this.authGuard.currentRouteData | async)?.['overflow']"
		 class="alaris-content">
		<router-outlet #outlet="outlet"></router-outlet>
	</div>
</alaris-root-content>
