import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { UsersService } from '../users.service';
import { User } from '@helpers/types/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-user-delete-dialog',
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersDeleteDialogComponent {

	private destroyRef = inject(DestroyRef);

	constructor(
		@Inject(DIALOG_DATA) public data: { user: User },
		public usersService: UsersService,
		private dialogRef: DialogRef<boolean>
	) {
	}

	confirm(): void {
		this.usersService.delete({ id: this.data.user.ID! })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if ( resp.Deleted ) {
					this.dialogRef.close(true);
				}
			});
	}

	close(): void {
		this.dialogRef.close(false);
	}

}
