import { APP_INITIALIZER, Injectable, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RulesComponent } from './pages/rules/rules.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SourcesComponent } from './pages/sources/sources.component';
import { UsersComponent } from './pages/users/users.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SignInLayoutComponent } from './layouts/sign-in-layout/sign-in-layout.component';
import { GoHomeComponent } from './layouts/go-home.component';
import { StyleComponentsLibsModule } from '@helpers/shared/style-components-libs.module';
import {
	AlarisApiService,
	AlarisConfigService,
	AlarisErrorInterceptor,
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisMultiOptions2Component,
	AlarisResponseInterceptor,
	AlarisTextAreaComponent,
	AUTH_SERVICE_INJECTOR,
	CONFIG_SERVICE_INJECTOR,
	ENVIRONMENT,
	ICONS_CONFIG,
	LANGUAGE_CONFIG,
	LANGUAGE_SERVICE_INJECTOR, LanguageConfig,
	LOCALSTORAGE_SERVICE_INJECTOR,
	MENU_MQ_SERVICE_INJECTOR,
	MENU_MQ_WIDTH_VALUE,
	MenuSidebarService,
	PROFILE_CONFIG,
	PROFILE_SERVICE_INJECTOR,
	ProfileConfig,
	China,
	English,
	Russian,
	Spain, AlarisRpcInterceptor
} from '@campaign-portal/components-library';
import { environment } from '../environments/environment';
import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams,
	TranslateDefaultParser,
	TranslateLoader,
	TranslateModule,
	TranslateParser
} from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { RpcInterceptor } from '@helpers/interceptors/rpc.interceptor';
import { AuthService } from './auth/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './auth/user.service';
import { PasswordDialogComponent } from './pages/profile/password-dialog/password-dialog.component';
import { EmptyTableComponent } from '@helpers/components/empty-table/empty-table.component';
import { UsersDeleteDialogComponent } from './pages/users/dialog/dialog.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { EditRuleComponent } from './pages/rules/edit-rule/edit-rule.component';
import { RulesDeleteDialogComponent } from './pages/rules/dialog/dialog.component';
import { ProductPipe, SourcePipe } from './_helpers/repo/repo.pipe';
import { ClientsDeleteDialogComponent } from './pages/clients/dialog/dialog.component';
import { EditClientComponent } from './pages/clients/edit-client/edit-client.component';
import { SourcesDeleteDialogComponent } from './pages/sources/dialog/dialog.component';
import { EditSourceComponent } from './pages/sources/edit-source/edit-source.component';
import { ExportConfig } from '@helpers/interceptors/export-config';
import { ImportSettingsDialogComponent } from './pages/settings/dialog/dialog.component';
import { STORAGE } from '@helpers/types/consts';
import { from, mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropAuthGuardService } from './auth/drop-auth.guard';
import { AuthGuardService } from './auth/auth.guard';

export const USERS_AND_ROLES_API = new InjectionToken<string>('UsersAndRolesAPIUrlPart');

/* Translate Module setup */
export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n_lib/app.', suffix: '.json?' + environment.version },
		{ prefix: './assets/i18n/app.', suffix: '.json?' + environment.version }
	]);
}

export class MissingTranslationService implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams): string {
		console.warn(`WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`);
		return params.key;
	}
}

@Injectable()
export class TranslateParserService extends TranslateDefaultParser {

	constructor() {
		super();
	}

	override getValue(target: any, key: string): any {
		const result = super.getValue(target, key);
		return typeof result === 'object' ? key : result;
	}

}

const translateConfig = {
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpBackend]
	},
	missingTranslationHandler: {
		provide: MissingTranslationHandler, useClass: MissingTranslationService
	},
	parser: { provide: TranslateParser, useClass: TranslateParserService },
	// if we set `defaultLanguage` to 'en' right here - we force loading en.json with translations
	// so missing translations will be used from en.file
	defaultLanguage: ''
};

/* End of Translate Module setup */

/* Config */
const profileConfig: ProfileConfig = {
	profileRoute: '/profile',
	termsUrl: '',
	privacyUrl: '',
	supportEmail: ''
};

const userAndRolesApi = {
	url: ''
};

const iconsConfig = {
	baseUrl: '/assets/icons'
};

export const languageConfig: LanguageConfig = { languages: [English, Spain, Russian, China], currentLanguage: English };

export function loadConfig(
	appConfig: AlarisConfigService<{ iconsBaseUlr?: string; showLogo?: boolean; showTitle?: boolean }>,
	store: AlarisLocalStorageService,
	translate: AlarisLanguageService,
	http: HttpClient
): () => Observable<any> {
	return (): Observable<any> => from(appConfig.load()).pipe(mergeMap(() => {
		const reqTo = 'HlrSettings.ReadStartupParams';
		return http.post<any>(appConfig.api + '?' + reqTo, {
			method: reqTo
		}).pipe(map(res => {
			const lang = store.get(STORAGE.LANGUAGE) ?? 'en';
			translateConfig.defaultLanguage = lang;
			registerLocaleData(localeEn, English.id, localeEnExtra);
			registerLocaleData(localeEs, Spain.id, localeEsExtra);
			registerLocaleData(localeRu, Russian.id, localeRuExtra);
			translate.set(lang);

			profileConfig.termsUrl = appConfig.termsUrl;
			profileConfig.privacyUrl = appConfig.privacyUrl;
			profileConfig.supportEmail = appConfig.supportEmail;

			userAndRolesApi.url = appConfig.api + 'usersroles/';
			iconsConfig.baseUrl = appConfig.env.iconsBaseUlr ?? '/assets/icons';

			if (!res.data?.enableAddLang) {
				languageConfig.languages = [English, Spain, China]
			}
		}));
	}));
}

/* End of Config */


@NgModule({
	declarations: [
		AppComponent,
		ClientsComponent,
		ProfileComponent,
		RulesComponent,
		SettingsComponent,
		SourcesComponent,
		UsersComponent,
		MainLayoutComponent,
		SignInLayoutComponent,
		GoHomeComponent,
		PasswordDialogComponent,
		EmptyTableComponent,
		UsersDeleteDialogComponent,
		EditUserComponent,
		EditRuleComponent,
		RulesDeleteDialogComponent,
		ProductPipe,
		SourcePipe,
		ClientsDeleteDialogComponent,
		EditClientComponent,
		SourcesDeleteDialogComponent,
		EditSourceComponent,
		ImportSettingsDialogComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,

		AppRoutingModule,

		TranslateModule.forRoot(translateConfig),
		StyleComponentsLibsModule,
		AlarisMultiOptions2Component,
		AlarisTextAreaComponent
	],
	providers: [
		AlarisConfigService<{ iconsBaseUlr?: string }>,
		AlarisLocalStorageService,
		AlarisLanguageService,
		AlarisApiService,

		{ provide: ENVIRONMENT, useValue: environment },
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [
				AlarisConfigService,
				AlarisLocalStorageService,
				AlarisLanguageService,
				HttpClient
			],
			multi: true
		},

		{ provide: LANGUAGE_CONFIG, useValue: languageConfig },

		{ provide: ICONS_CONFIG, useValue: iconsConfig },
		{ provide: PROFILE_CONFIG, useValue: profileConfig },

		{ provide: CONFIG_SERVICE_INJECTOR, useExisting: AlarisConfigService },
		{ provide: LOCALSTORAGE_SERVICE_INJECTOR, useExisting: AlarisLocalStorageService },
		{ provide: LANGUAGE_SERVICE_INJECTOR, useExisting: AlarisLanguageService },
		{ provide: AUTH_SERVICE_INJECTOR, useExisting: AuthService },
		{ provide: PROFILE_SERVICE_INJECTOR, useExisting: UserService },

		{ provide: HTTP_INTERCEPTORS, useClass: RpcInterceptor, multi: true },

		{ provide: HTTP_INTERCEPTORS, useClass: AlarisRpcInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisResponseInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisErrorInterceptor, multi: true },

		{ provide: HTTP_INTERCEPTORS, useClass: ExportConfig, multi: true },

		{ provide: MENU_MQ_SERVICE_INJECTOR, useClass: MenuSidebarService },
		{ provide: MENU_MQ_WIDTH_VALUE, useValue: '(max-width: 1365px)' },

		{ provide: USERS_AND_ROLES_API, useValue: userAndRolesApi },
		AuthGuardService,
		DropAuthGuardService,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
