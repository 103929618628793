import { Injectable } from '@angular/core';
import { AbstractCRUDService } from '@campaign-portal/components-library';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsersAndRolesService } from '@helpers/api/users-and-roles.service';
import {
	UsersCreateResponse,
	UsersDeleteRequest,
	UsersDeleteResponse,
	UsersReadRequest,
	UsersReadResponse,
	UsersUpdateResponse
} from '@helpers/types/user-and-roles';
import { User } from '@helpers/types/models';
import { ExportService } from '@helpers/api/export.service';
import { UsersFieldsService } from './users.fields.service';

@Injectable({
	providedIn: 'root'
})
export class UsersService implements AbstractCRUDService {

	readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	readonly entity = '';
	readonly title = '';
	readonly create = this.update;

	constructor(
		private readonly usersAndRoles: UsersAndRolesService,
		private readonly fields: UsersFieldsService,
		private readonly exportService: ExportService
	) {
	}

	read(params: UsersReadRequest): Observable<UsersReadResponse> {
		const updatedParams = {
			...params,
			Sorting: [
				...(params.Sorting ?? []),
				{
					Field: 'ID',
					Dir: 'desc'
				}
			]
		};
		return this.usersAndRoles.loader<UsersReadResponse>('Users.Read', updatedParams, this.loading$);
	}

	update(params: User): Observable<UsersUpdateResponse | UsersCreateResponse> {
		const method = params.ID ? 'Users.Update' : 'Users.Create';
		return this.usersAndRoles.loader<UsersUpdateResponse | UsersCreateResponse>(method, params, this.loading$);
	}

	delete(params: UsersDeleteRequest): Observable<UsersDeleteResponse> {
		return this.usersAndRoles.loader<UsersDeleteResponse>('Users.Delete', params, this.loading$);
	}

	export(total: number): Observable<void> {
		return this.exportService.export(
			this.usersAndRoles.userAndRolesApi.url,
			'Users.Read',
			{
				Paging: {
					Skip: 0,
					Take: total
				}
			},
			this.fields.headers,
			'users'
		);
	}

}
