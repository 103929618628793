import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { AlarisEditPanelService, EditPanelInputData } from '@campaign-portal/components-library';
import { User } from '@helpers/types/models';
import { UsersService } from '../users.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrl: './edit-user.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserComponent implements OnInit {

	readonly user: User = {
		ID: null,
		RolesIds: [],
		Login: '',
		Email: '',
	};
	readonly userForm = new FormGroup({
		login: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
		email: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
		firstName: new FormControl(),
		lastName: new FormControl(),
		password: new FormControl<string | null>(null),
		pageSize: new FormControl(25),
	});
	readonly pageSize = [25, 50, 100, 200];

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: { user?: User },
		public readonly usersService: UsersService,
		private readonly editPanel: AlarisEditPanelService
	) {
		if ( this.inputData.user ) {
			this.user = this.inputData.user;
		}
	}

	ngOnInit(): void {
		this.reset();
		if ( !this.user.ID ) {
			this.userForm.controls.password.addValidators(Validators.required);
		}
	}

	save(): void {
		const user = {
			ID: this.user.ID,
			Login: this.userForm.controls.login.value,
			Email: this.userForm.controls.email.value,
			Details: this.userForm.controls.firstName.value || this.userForm.controls.lastName.value
				|| this.userForm.controls.pageSize.value
				? {
					firstName: this.userForm.controls.firstName.value,
					lastName: this.userForm.controls.lastName.value,
					pageSize: this.userForm.controls.pageSize.value
				}
				: undefined,
			RolesIds: this.user.RolesIds ?? [],
			Password: this.userForm.controls.password.value
		};
		this.usersService.update(user)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if (
					Object.prototype.hasOwnProperty.call(resp, 'Updated')
					|| Object.prototype.hasOwnProperty.call(resp, 'Created')
				) {
					this.editPanel.close(true);
				}
			});
	}

	close(): void {
		this.editPanel.close(false);
	}

	reset(): void {
		const user = structuredClone(this.user);
		this.userForm.reset({
			login: user.Login,
			email: user.Email,
			firstName: user.Details?.firstName,
			lastName: user.Details?.lastName,
			password: null,
			pageSize: user.Details?.pageSize
		});
	}

}
