<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [loading$]="sourcesService.loading$"
			   size="md">
	<h6 slot="header">
		@if (rules) {
			{{ 'sources.removingErrorDialog' | translate }}
		} @else {
			<h6 slot="header">{{ 'sources.removingDialog' | translate }}</h6>
		}
	</h6>
	<div slot="content">
		@if (rules) {
			@for (id of rules; track id) {
				<li><strong>{{ 'rules.id' | translate }}: {{ id }}</strong></li>
			}
		} @else {
			<h3><strong>{{ data.source.Name }}</strong></h3>
		}
	</div>
</alaris-dialog>