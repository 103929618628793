import { Injectable, OnDestroy } from '@angular/core';
import { EntityField, InputType } from '@campaign-portal/namespace/common/entityField';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { AbstractCRUDService } from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class UsersFieldsService implements AbstractCRUDService, OnDestroy {
	readonly entity = '';
	readonly title = '';

	readonly name: EntityField = {
		id: 0,
		name: 'users_roles.users.name',
		variable: 'Details',
		filterType: FilterType.LIKE,
		required: true,
		type: InputType.TEXT
	};
	readonly login: EntityField = {
		id: 1,
		name: 'users_roles.users.login',
		variable: 'Login',
		filterType: FilterType.LIKE,
		required: true,
		type: InputType.TEXT
	};
	readonly email: EntityField = {
		id: 1,
		name: 'users_roles.users.email',
		variable: 'Email',
		filterType: FilterType.LIKE,
		required: true,
		type: InputType.TEXT
	};

	readonly headers: EntityField[] = [
		this.login,
		this.email,
		this.name
	];
	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	create(): Observable<void> {
		return of();
	}

	update(): Observable<void> {
		return of();
	}

	delete(): Observable<void> {
		return of();
	}

}
