import { Injectable } from '@angular/core';
import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisConfigService,
	AlarisLocalStorageService
} from '@campaign-portal/components-library';
import { ReadRequest, ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client } from '@helpers/types/models';
import {
	ClientsDeleteRequest,
	ClientsDeleteResponse,
	ClientsReadRequest,
	ClientsReadResponse,
	ClientsUpdateRequest,
	ClientsUpdateResponse
} from '@helpers/types/hlr';
import { map } from 'rxjs/operators';
import { Filter, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ExportService } from '@helpers/api/export.service';
import { ClientsFieldsService } from './clients.fields.service';
import { STORAGE } from '@helpers/types/consts';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';

@Injectable({
	providedIn: 'root'
})
export class ClientsService implements AbstractCRUDService {
	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService,
		private readonly config: AlarisConfigService,
		private readonly fields: ClientsFieldsService,
		private readonly exportService: ExportService,
		private readonly storage: AlarisLocalStorageService
	) {
	}

	read(params: ReadRequest): Observable<ReadResponse<Client[]>> {
		const parsedParams: ClientsReadRequest = this.prepareParams(params);
		return this.api.loader<ClientsReadResponse>('Clients.Read', parsedParams, this.loading$)
			.pipe(map((resp) => {
				return {
					Success: !!resp.data,
					Total: resp.length,
					Data: resp.data
				};
			}));
	}

	update(params: ClientsUpdateRequest): Observable<ClientsUpdateResponse> {
		return this.api.loader<ClientsUpdateResponse>(
			params.id ? 'Clients.Update' : 'Clients.Create',
			params,
			this.loading$
		);
	}

	delete(params: ClientsDeleteRequest): Observable<ClientsDeleteResponse> {
		return this.api.loader<ClientsDeleteResponse>('Clients.Delete', params, this.loading$);
	}

	export(total: number, params: RPCRequestParams): Observable<void> {
		params = {
			...params,
			Paging: { Skip: 0, Take: total }
		};
		return this.exportService.export(
			this.config.api,
			'Clients.Read',
			this.prepareParams(params),
			this.fields.headers,
			'clients'
		);
	}

	private prepareParams(params: RPCRequestParams): ClientsReadRequest {
		return {
			offset: params.Paging?.Skip ?? 0,
			limit: params.Paging?.Take ?? parseInt(this.storage.get(STORAGE.PAGE_SIZE) ?? '25', 10),
			active: 'login',
			direction: 'asc',
			filters: params.Filters
				? params.Filters.map((filter) => {
					return this.parseFilterField(filter);
				})
				: []
		};
	}

	private parseFilterField(filter: Filter): { key: string, value: string } {
		const variable = filter.Field;
		const value = filter.Value;
		switch (variable) {
			case 'productId':
				return { key: 'product_id', value: (value as ValueObject).value + '' };
			default:
				return { key: variable, value: value + '' };
		}
	}

}
