import { ChangeDetectionStrategy, Component, DestroyRef, HostListener, Inject, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-password-dialog',
	templateUrl: './password-dialog.component.html',
	styleUrl: './password-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordDialogComponent {


	readonly password = new FormControl<string | null>(null);
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly errors: { key: string; value: string }[] = [{
		key: 'wrong_password', value: 'users_roles.users.wrong_password'
	}];
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		private readonly authService: AuthService,
		private readonly dialogRef: DialogRef<boolean>,
		@Inject(DIALOG_DATA) public readonly data: { login: string }
	) {
	}

	@HostListener('keydown.enter')
	confirm(): void {
		this.loading$.next(true);
		this.authService.isPasswordCorrect({
			Login: this.data.login,
			Password: this.password.value!
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (resp) => {
					if ( resp.IsCorrect ) {
						this.dialogRef.close(true);
					} else {
						this.password.setErrors({ wrong_password: true });
					}
				},
				complete: () => {
					this.loading$.next(false);
				}
			});

	}

	close(): void {
		this.dialogRef.close(false);
	}


}
