import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UsersFieldsService } from './users.fields.service';
import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisLocalStorageService,
	AlarisTableStateService,
	EditPanelWidth,
	RowActionSimple,
	TABLE_STATE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { User } from '@helpers/types/models';
import { TableSettingsService } from '@helpers/api/table-settings.service';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { Paging } from '@campaign-portal/namespace/common/rpc.params';
import { UsersService } from './users.service';
import { UsersDeleteDialogComponent } from './dialog/dialog.component';
import { BehaviorSubject } from 'rxjs';
import { EditUserComponent } from './edit-user/edit-user.component';
import { STORAGE } from '@helpers/types/consts';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrl: '../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
	providers: [
		AlarisTableStateService,
		{ provide: TABLE_STATE_SERVICE_INJECTOR, useExisting: AlarisTableStateService }
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent extends AlarisComplexTableComponent<User> implements OnDestroy {

	override readonly rowActions: RowActionSimple<any>[] = [
		{
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		}
	];
	override readonly dblClickRowAction = this.edit;
	override pageSize = parseInt(this.storage.get(STORAGE.PAGE_SIZE) ?? '25', 10);
	override readonly pageSizeOptions = [25, 50, 100, 200];
	override readonly paging: Paging = {
		Skip: this.activePage,
		Take: this.pageSize
	};

	readonly exporting$ = new BehaviorSubject<boolean>(false);

	constructor(
		dataService: UsersService,
		fieldsService: UsersFieldsService,
		tableSettingsService: TableSettingsService,
		private readonly storage: AlarisLocalStorageService,
		private readonly dialog: AlarisDialogService,
		private readonly usersService: UsersService
	) {
		super(
			dataService,
			fieldsService,
			tableSettingsService,
			TableTypes.INTERNAL
		);
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.exporting$.next(false);
		this.exporting$.complete();
	}

	edit(user?: User): void {
		this.editPanel.open<EditUserComponent, { user: User }, boolean>(EditUserComponent, EditPanelWidth.SM, { user })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	delete(user: User): void {
		this.dialog.open<boolean>(UsersDeleteDialogComponent, { autoFocus: false, data: { user } })
			.closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	export(): void {
		this.exporting$.next(true);
		this.usersService.export(this.total)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.exporting$.next(false);
			});
	}

}
