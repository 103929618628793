import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisLocalStorageService,
	AlarisTableStateService,
	EditPanelWidth,
	RowActionSimple,
	TABLE_STATE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { Client } from '@helpers/types/models';
import { STORAGE } from '@helpers/types/consts';
import { Paging } from '@campaign-portal/namespace/common/rpc.params';
import { BehaviorSubject } from 'rxjs';
import { TableSettingsService } from '@helpers/api/table-settings.service';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { ClientsService } from './clients.service';
import { ClientsFieldsService } from './clients.fields.service';
import { ProductsService } from '@helpers/repo/products.service';
import { ClientsDeleteDialogComponent } from './dialog/dialog.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RepoService } from '@helpers/repo/repo.service';

@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html',
	styleUrls: [
		'../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./clients.component.scss'
	],
	providers: [
		AlarisTableStateService,
		{ provide: TABLE_STATE_SERVICE_INJECTOR, useExisting: AlarisTableStateService }
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsComponent extends AlarisComplexTableComponent<Client> implements OnDestroy {

	override readonly rowActions: RowActionSimple<any>[] = [
		{
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		}
	];
	override readonly dblClickRowAction = this.edit;
	override pageSize = parseInt(this.storage.get(STORAGE.PAGE_SIZE) ?? '25', 10);
	override readonly pageSizeOptions = [25, 50, 100, 200];
	override readonly paging: Paging = {
		Skip: this.activePage,
		Take: this.pageSize
	};

	readonly exporting$ = new BehaviorSubject<boolean>(false);

	constructor(
		dataService: ClientsService,
		public override readonly fieldsService: ClientsFieldsService,
		tableSettingsService: TableSettingsService,
		private readonly repo: RepoService,
		private readonly storage: AlarisLocalStorageService,
		private readonly dialog: AlarisDialogService,
		private readonly clientsService: ClientsService,
		public readonly productsService: ProductsService
	) {
		super(
			dataService,
			fieldsService,
			tableSettingsService,
			TableTypes.INTERNAL
		);
		this.state.filters
			.set('login', { enabled: false })
			.set('availableInterfaces', { enabled: false })
			.set('maxSessions', { enabled: false });
		this.repo.load(['hlr']).pipe(takeUntilDestroyed()).subscribe();
		this.productsService.clientProducts$
			.pipe(takeUntilDestroyed())
			.subscribe((list) => {
				const data = list.map((item) => {
					return { name: item.product_caption, value: item.product_id };
				});
				this.setRefBooksInFields({
					productId: { list$: new BehaviorSubject<{ name: string; value: number }[]>(data) }
				});
			});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.exporting$.next(false);
		this.exporting$.complete();
	}

	edit(client?: Client): void {
		this.editPanel.open<EditClientComponent, {
			client: Client
		}, boolean>(EditClientComponent, EditPanelWidth.SM, { client })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	delete(client: Client): void {
		this.dialog.open<boolean>(ClientsDeleteDialogComponent, { autoFocus: false, data: { client } })
			.closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	export(): void {
		this.exporting$.next(true);
		this.clientsService.export(this.total, this.state.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.exporting$.next(false);
			});
	}

}
