import { Injectable, OnDestroy } from '@angular/core';
import { AbstractCRUDService } from '@campaign-portal/components-library';
import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';

@Injectable({
	providedIn: 'root'
})
export class ClientsFieldsService implements AbstractCRUDService, OnDestroy {

	readonly login: EntityField = {
		id: 0,
		name: 'clients.login',
		variable: 'login',
		filterType: FilterType.LIKE,
		required: false,
		type: InputType.TEXT
	};
	readonly productId: EntityField = {
		id: 1,
		name: 'clients.client_product',
		variable: 'productId',
		filterType: FilterType.IN,
		required: false,
		type: InputComplexType.SELECT,
		data: []
	};
	readonly availableInterfaces: EntityField = {
		id: 2,
		name: 'clients.available_interfaces',
		variable: 'availableInterfaces',
		filterType: FilterType.IN,
		required: false,
		type: InputComplexType.MULTISELECT,
		data: []
	};
	readonly maxSessions: EntityField = {
		id: 3,
		name: 'clients.max_sessions',
		variable: 'maxSessions',
		filterType: FilterType.BETWEEN,
		required: false,
		type: InputComplexType.RANGE,
		data: []
	};

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly headers: EntityField[] = [
		this.login,
		this.productId,
		this.availableInterfaces,
		this.maxSessions
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});

	}

	create(): Observable<void> {
		return of();
	}

	update(): Observable<void> {
		return of();
	}

	delete(): Observable<void> {
		return of();
	}
}
