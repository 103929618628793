<alaris-card [alarisLoading]="settingsService.loading$">
	<form (submit)="update()" [formGroup]="form" class="form">
		<div class="row">
			<alaris-input formControlName="defaultConnectionTimeout" type="number" [errors]="errors">
				{{ 'settings.connection_timeout' | translate }}
			</alaris-input>

			<alaris-input formControlName="defaultRequestTimeout" type="number" [errors]="errors">
				{{ 'settings.request_timeout' | translate }}
			</alaris-input>

			<alaris-input formControlName="concurrentRequestTimeout" type="number" [errors]="errors">
				{{ 'settings.concurrent_request_timeout' | translate }}
			</alaris-input>
		</div>

		<alaris-input class="row" formControlName="apiHost">
			{{ 'settings.api' | translate }}
		</alaris-input>
		<alaris-input class="row" formControlName="dnisLimit" type="number" [errors]="errors">
			{{ 'settings.dnisLimit' | translate }}
		</alaris-input>


		<div class="row">
			<alaris-checkbox formControlName="enableGlobalProducts">
				{{ 'settings.check_products' | translate }}
			</alaris-checkbox>
			<alaris-checkbox formControlName="enableGlobalSenders">
				{{ 'settings.check_senders' | translate }}
			</alaris-checkbox>
		</div>

		<div class="row">
			<button [disabled]="form.pristine || form.invalid"
					alaris-button bType="primary" type="submit">
				{{ 'gl.submit' | translate }}
			</button>
			@if (this.user.isAdmin) {
				<button (click)="import()"
						alaris-button bType="secondary" type="button">
					{{ 'settings.import' | translate }}
				</button>
			}
		</div>
	</form>
</alaris-card>
