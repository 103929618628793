import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, Observable, of } from 'rxjs';
import { ProductsService } from '@helpers/repo/products.service';
import { Product, ProductsType, Source } from '@helpers/types/models';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { map } from 'rxjs/operators';
import { SourcesService } from '../../pages/sources/sources.service';
import { LocalAddressesService } from '@helpers/repo/local-addresses.service';
import { ProvidersService } from '../../pages/sources/providers.service';

export enum RepoType {
	PRODUCT = 'product',
	SOURCE = 'source'
}

@Injectable({
	providedIn: 'root'
})
export class RepoService {

	public readonly loaded$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly products: ProductsService,
		private readonly sources: SourcesService,
		private readonly localAddresses: LocalAddressesService,
		private readonly providers: ProvidersService
	) {
	}

	public transformProduct(id: Id<exist>): Product | null {
		return this.find<Product>(id, this.products.map, RepoType.PRODUCT);
	}

	public transformSource(id: Id<exist>): Source | null {
		return this.find<Source>(id, this.sources.map, RepoType.SOURCE);
	}

	load(productsTypes: ProductsType[]): Observable<unknown> {
		const repositoriesRequests: Observable<unknown>[] = [
			this.products.loadProducts(productsTypes).pipe(catchError((resp) => {
				resp.data = [];
				return of(resp);
			})),
			this.sources.load().pipe(catchError((resp) => {
				resp.data = [];
				return of(resp);
			})),
			this.localAddresses.load().pipe(catchError((resp) => {
				resp.data = [];
				return of(resp);
			}))
		];

		return forkJoin(repositoriesRequests).pipe(
			map((resp) => {
				this.loaded$.next(true);
				return resp;
			})
		);
	}

	reset(): void {
		this.products.clear();
		this.sources.clear();
		this.localAddresses.clear();
		this.providers.clear();
	}

	private find<T>(
		ids: Id<exist>,
		dataMap: Map<number, T>,
		repoType: RepoType
	): T | null {
		switch (repoType) {
			default:
				return dataMap.get(ids) ?? null;
		}
	}
}
