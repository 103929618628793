import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExportService } from '@helpers/api/export.service';
import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisConfigService,
	AlarisLocalStorageService
} from '@campaign-portal/components-library';
import { RulesFieldsService } from './rules.fields.service';
import { ReadRequest, ReadResponse } from '@campaign-portal/namespace/common/implementations';
import {
	RulesDeleteRequest,
	RulesDeleteResponse,
	RulesReadRequest,
	RulesReadResponse,
	RulesUpdateRequest,
	RulesUpdateResponse
} from '@helpers/types/hlr';
import { STORAGE } from '@helpers/types/consts';
import { Rule } from '@helpers/types/models';
import { map } from 'rxjs/operators';
import { Filter, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';

@Injectable({
	providedIn: 'root'
})
export class RulesService implements AbstractCRUDService {
	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService,
		private readonly config: AlarisConfigService,
		private readonly fields: RulesFieldsService,
		private readonly exportService: ExportService,
		private readonly storage: AlarisLocalStorageService
	) {
	}

	read(params: ReadRequest): Observable<ReadResponse<Rule[]>> {
		const parsedParams: RulesReadRequest = this.prepareParams(params);
		return this.api.loader<RulesReadResponse>('Rules.Read', parsedParams, this.loading$)
			.pipe(
				map((resp) => {
					// TODO fix backend product ids received as strings for some reason
					resp.data = resp.data.map((rule) => {
						if ( rule.productList ) {
							rule.productList = rule.productList.map(item => +item);
						}
						return rule;
					});
					return resp;
				}),
				map((resp) => {
					return {
						Success: !!resp.data,
						Total: resp.length,
						Data: resp.data
					};
				})
			);
	}

	update(params: RulesUpdateRequest): Observable<RulesUpdateResponse> {
		return this.api.loader<RulesUpdateResponse>(
			params.ruleId ? 'Rules.Update' : 'Rules.Create',
			params,
			this.loading$
		);
	}

	delete(params: RulesDeleteRequest): Observable<RulesDeleteResponse> {
		return this.api.loader<RulesDeleteResponse>('Rules.Delete', params, this.loading$);
	}

	export(total: number, params: RPCRequestParams): Observable<void> {
		params = {
			...params,
			Paging: { Skip: 0, Take: total }
		};
		return this.exportService.export(
			this.config.api,
			'Rules.Read',
			this.prepareParams(params),
			this.fields.headers,
			'rules'
		);
	}

	private prepareParams(params: RPCRequestParams): RulesReadRequest {
		return {
			offset: params.Paging?.Skip ?? 0,
			limit: params.Paging?.Take ?? parseInt(this.storage.get(STORAGE.PAGE_SIZE) ?? '25', 10),
			active: 'rule_name',
			direction: 'asc',
			filter: params.Filters
				? params.Filters.map((filter) => {
					return this.parseFilterField(filter);
				})
				: []
		};
	}

	private parseFilterField(filter: Filter): { key: string, value: string } {
		const variable = filter.Field;
		const value = filter.Value;
		const productValues: string[] = [];
		switch (variable) {
			case 'choiceListName':
				return { key: 'choice_list_name', value: value + '' };
			case 'prefixList':
				return { key: 'prefix_detailed_list', value: value + '' };
			case 'productList':
				(value as {name: string, value: number}[]).forEach(val => {
					productValues.push(val.value.toString());
				});
				return { key: 'product_list', value: productValues + '' };
			default:
				return { key: variable, value: value + '' };
		}
	}
}
