import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject, Inject } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

import {
	AlarisConfigService,
	AlarisContentLayoutService,
	LAYOUT_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { routerLinks } from '@helpers/shared/router-links.const';
import { AuthGuardService } from '../../auth/auth.guard';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

function mainLayoutServiceFact(elementRef: ElementRef, router: Router): AlarisContentLayoutService {
	return new AlarisContentLayoutService(elementRef.nativeElement, router);
}

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: LAYOUT_SERVICE_INJECTOR, useFactory: mainLayoutServiceFact, deps: [ElementRef, Router] }],
	animations: [
		trigger('mainLayoutChanged', [
			transition('* <=> *', [
				style({ height: 0, opacity: 0 }),
				animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '*', opacity: 1 }))
			])
		]),
		trigger(
			'inOutAnimation',
			[
				transition(
					':enter',
					[
						style({ width: 0, opacity: 0 }),
						animate('150ms ease-out',
							style({ width: 240, opacity: 1 }))
					]
				),
				transition(
					':leave',
					[
						style({ width: 240, opacity: 1 }),
						animate('150ms ease-in',
							style({ width: 0, opacity: 0 }))
					]
				)
			]
		)
	]
})
export class MainLayoutComponent {
	readonly globals = window.frames?.frameInVoice?.Globals;
	readonly menuList = routerLinks;
	dynamicTitle = '';

	// loading$ = this.repo.loaded$.pipe(map((loaded) => {
	// 	return !loaded;
	// }));

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(LAYOUT_SERVICE_INJECTOR) public readonly layoutService: AlarisContentLayoutService,
		public readonly config: AlarisConfigService<{ iconsBaseUlr?: string; showLogo?: boolean; showTitle?: boolean }>,
		public readonly auth: AuthService,
		public readonly authGuard: AuthGuardService,
		private readonly router: Router
	) {

		this.router.events.pipe(
			map(e => {
				if ( e instanceof NavigationStart ) {
					this.dynamicTitle = this.router.getCurrentNavigation()?.extras?.state?.['title'];
				}
				return e;
			}),
			takeUntilDestroyed(this.destroyRef)
		).subscribe();

	}


	prepareInterfaceRoute(outlet: RouterOutlet): boolean {
		return outlet &&
			outlet.activatedRouteData &&
			outlet.activatedRouteData['animationState'];
	}

}
