import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { CurrentUser, CurrentUserComponentsWrapper } from '@helpers/types/types-and-interfaces';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	email: string | null = null;
	id: number | null = null;
	login: string | null = null;
	permissions: string[] = [];
	details?: Record<string, unknown>;

	readonly user$ = new BehaviorSubject<CurrentUserComponentsWrapper>({
		email: ''
	});

	constructor() {
	}

	get isAdmin(): boolean {
		return this.login === 'admin' || this.id === -1;
	}

	register(user: CurrentUser): void {
		this.email = user.email;
		this.id = user.id;
		this.login = user.login;
		this.permissions = user.permissions;
		this.details = user.details;
		this.user$.next({
			email: user.email,
			details: user.details ?? {
				...user.details!,
				firstName: user.details?.['FirstName'],
				lastName: user.details?.['LastName']
			}
		});
	}

	reset(): void {
		this.email = null;
		this.id = null;
		this.login = null;
		this.permissions = [];
	}

	allowed(permissions: string[]): boolean {
		for ( const permission of permissions ) {
			if ( !this.permissions.includes(permission) ) {
				return false;
			}
		}
		return true;
	}
}
