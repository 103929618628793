import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisLocalStorageService,
	AlarisTableStateService,
	EditPanelWidth,
	RowActionSimple,
	TABLE_STATE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { Rule } from '@helpers/types/models';
import { STORAGE } from '@helpers/types/consts';
import { Paging } from '@campaign-portal/namespace/common/rpc.params';
import { BehaviorSubject } from 'rxjs';
import { TableSettingsService } from '@helpers/api/table-settings.service';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { RulesService } from './rules.service';
import { RulesFieldsService } from './rules.fields.service';
import { EditRuleComponent } from './edit-rule/edit-rule.component';
import { RulesDeleteDialogComponent } from './dialog/dialog.component';
import { ProductsService } from '@helpers/repo/products.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RepoService } from '@helpers/repo/repo.service';

@Component({
	selector: 'app-rules',
	templateUrl: './rules.component.html',
	styleUrls: [
		'../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'rules.component.scss'
	],
	providers: [
		AlarisTableStateService,
		{ provide: TABLE_STATE_SERVICE_INJECTOR, useExisting: AlarisTableStateService }
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RulesComponent extends AlarisComplexTableComponent<Rule> implements OnDestroy {

	override readonly rowActions: RowActionSimple<any>[] = [
		{
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		}
	];
	override readonly dblClickRowAction = this.edit;
	override pageSize = parseInt(this.storage.get(STORAGE.PAGE_SIZE) ?? '25', 10);
	override readonly pageSizeOptions = [25, 50, 100, 200];
	override readonly paging: Paging = {
		Skip: this.activePage,
		Take: this.pageSize
	};

	readonly exporting$ = new BehaviorSubject<boolean>(false);

	constructor(
		dataService: RulesService,
		public override readonly fieldsService: RulesFieldsService,
		tableSettingsService: TableSettingsService,
		private readonly repo: RepoService,
		private readonly storage: AlarisLocalStorageService,
		private readonly dialog: AlarisDialogService,
		private readonly rulesService: RulesService,
		public readonly productsService: ProductsService
	) {
		super(
			dataService,
			fieldsService,
			tableSettingsService,
			TableTypes.INTERNAL
		);
		this.state.filters
			.set('ruleId', { enabled: false })
			.set('senderList', { enabled: false });
		this.repo.load(['hlr', 'sms']).pipe(takeUntilDestroyed()).subscribe();
		this.productsService.clientProducts$
			.pipe(takeUntilDestroyed())
			.subscribe((list) => {
				if (list.length > 0) {
					const data = list.map((item) => {
						return { name: item.product_caption, value: item.product_id };
					});
					this.setRefBooksInFields({
						productList:
							{ list$: new BehaviorSubject<{ name: string; value: number }[]>(data),
								loading$: this.fieldsService.loading$
							},
					});
				}
			});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.exporting$.next(false);
		this.exporting$.complete();
	}

	edit(rule?: Rule): void {
		this.editPanel.open<EditRuleComponent, { rule: Rule }, boolean>(EditRuleComponent, EditPanelWidth.SM, { rule })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	delete(rule: Rule): void {
		this.dialog.open<boolean>(RulesDeleteDialogComponent, { autoFocus: false, data: { rule } })
			.closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	export(): void {
		this.exporting$.next(true);
		this.rulesService.export(this.total, this.state.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.exporting$.next(false);
			});
	}

}
