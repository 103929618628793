import { NgModule } from '@angular/core';
import {
	AlarisAlertComponent,
	AlarisButtonComponent,
	AlarisCardComponent,
	AlarisCellContextDirective,
	AlarisCheckboxComponent,
	AlarisDialogComponent,
	AlarisDisplayFilterPipe,
	AlarisDropdownActionsComponent,
	AlarisEditPanelComponent,
	AlarisFadeComponent,
	AlarisFadeDirective,
	AlarisFilterComponent,
	AlarisFilterControlComponent,
	AlarisFilterManagerComponent,
	AlarisIconDirective,
	AlarisInputComponent,
	AlarisInputNumberComponent,
	AlarisLanguageComponent,
	AlarisLinkComponent,
	AlarisLoadingDirective,
	AlarisMenuSidebarComponent,
	AlarisMultiSelect2Component,
	AlarisMultiSelectFilter2Component,
	AlarisNavbarComponent,
	AlarisOptions2Component,
	AlarisOverflowFaderDirective,
	AlarisPaginationComponent,
	AlarisProfileComponent,
	AlarisResizableColumnDirective,
	AlarisRootContentComponent,
	AlarisSelect2Component,
	AlarisSelectFilter2Component,
	AlarisSettingsManagerComponent,
	AlarisSpinnerComponent,
	AlarisTableComponent,
	AlarisTableSelectionBarComponent,
	AlarisTagComponent,
	AlarisToasterModule,
	AlarisToggleComponent,
	AlarisTooltipDirective,
	AlarisVirtualForDirective
} from '@campaign-portal/components-library';
import { DragDropModule } from '@angular/cdk/drag-drop';

const standAlones = [
	AlarisLanguageComponent,
	AlarisInputComponent,
	AlarisLoadingDirective,
	AlarisLinkComponent,
	AlarisButtonComponent,
	AlarisCardComponent,
	AlarisMenuSidebarComponent,
	AlarisRootContentComponent,
	AlarisNavbarComponent,
	AlarisProfileComponent,
	AlarisSelect2Component,
	AlarisOptions2Component,
	AlarisAlertComponent,
	AlarisIconDirective,
	AlarisDialogComponent,
	AlarisTooltipDirective,

	AlarisOverflowFaderDirective,
	AlarisPaginationComponent,
	AlarisFilterComponent,
	AlarisTableComponent,
	AlarisTableSelectionBarComponent,
	AlarisFilterManagerComponent,
	AlarisFilterControlComponent,
	AlarisSettingsManagerComponent,
	AlarisDropdownActionsComponent,
	AlarisResizableColumnDirective,
	AlarisDisplayFilterPipe,
	AlarisEditPanelComponent,
	AlarisCellContextDirective,
	AlarisFadeComponent,
	AlarisFadeDirective,
	AlarisSpinnerComponent,
	AlarisCheckboxComponent,
	AlarisTagComponent,
	AlarisMultiSelect2Component,
	AlarisSelect2Component,
	AlarisMultiSelectFilter2Component,
	AlarisSelectFilter2Component,
	AlarisToggleComponent,
	AlarisVirtualForDirective,
	AlarisInputNumberComponent
];

@NgModule({
	imports: [
		AlarisToasterModule.forRoot(),
		...standAlones,

		// CDK
		DragDropModule


	],
	exports: [
		AlarisToasterModule,
		...standAlones,

		// CDK
		DragDropModule
	]
})
export class StyleComponentsLibsModule {
}
