<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [confirmButtonText]="'gl.pass_confirm'"
			   [disabled]="password.invalid"
			   [loading$]="loading$">
	<h3 slot="header">
		{{ 'gl.pass_confirm' | translate }}
	</h3>
	<div class="content" slot="content">
		<alaris-input [autofocus]="true" [errors]="errors" [formControl]="password" class="w-100" type="password">
			{{ 'users_roles.users.password' | translate }}
		</alaris-input>
	</div>
</alaris-dialog>