import { Injectable } from '@angular/core';
import { Source } from '@helpers/types/models';
import { Observable } from 'rxjs';
import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisConfigService,
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisToasterService,
	ExtendableRefBookService
} from '@campaign-portal/components-library';
import { map } from 'rxjs/operators';
import {
	RulesFindSourceInRulesRequest,
	RulesFindSourceInRulesResponse,
	SourcesDeleteRequest,
	SourcesDeleteResponse,
	SourcesReadAllResponse,
	SourcesReadRequest,
	SourcesReadResponse,
	SourcesUpdateRequest,
	SourcesUpdateResponse
} from '@helpers/types/hlr';
import { ReadRequest, ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { exist } from '@campaign-portal/namespace/common/id';
import { Filter, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ExportService } from '@helpers/api/export.service';
import { STORAGE } from '@helpers/types/consts';
import { SourcesFieldsService } from './sources.fields.service';

@Injectable({
	providedIn: 'root'
})
export class SourcesService extends ExtendableRefBookService<Source<exist>> implements AbstractCRUDService {

	readonly entity = '';
	readonly title = '';
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService,
		private readonly fields: SourcesFieldsService,
		private readonly exportService: ExportService,
		private readonly config: AlarisConfigService,
		private readonly storage: AlarisLocalStorageService,
		private readonly toaster: AlarisToasterService,
		private readonly lService: AlarisLanguageService
	) {
		super();
	}

	override load(): Observable<ReadResponse<Source<exist>[]>> {
		return this.api.loader<SourcesReadAllResponse>('Sources.ReadAll', {}, this.loading$)
			.pipe(
				map((resp) => {
					return {
						Success: true,
						Total: resp.length,
						Data: resp.data
					};
				}),
				map((resp) => {
					return this.process(resp);
				})
			);
	}

	override process(resp: ReadResponse<Source<exist>[]>): ReadResponse<Source<exist>[]> {
		this.list$.next(resp.Data);
		this.map$.next(this.list.reduce((result, item) => {
			result.set(item.SourceId, item);
			return result;
		}, new Map()));
		return resp;
	}

	read(params: ReadRequest): Observable<ReadResponse<Source[]>> {
		const parsedParams: SourcesReadRequest = this.prepareParams(params);
		return this.api.loader<SourcesReadResponse>('Sources.Read', parsedParams, this.loading$)
			.pipe(map((resp) => {
				return {
					Success: true,
					Total: resp.length,
					Data: resp.data
				};
			}));
	}

	update(params: SourcesUpdateRequest): Observable<SourcesUpdateResponse> {
		this.prepareParamsBeforeCreate(params);
		const method = params.SourceId === null ? 'Sources.Create' : 'Sources.Update';
		return this.api.loader<SourcesUpdateResponse>(method, params, this.loading$)
			.pipe(map((resp) => {
				if ( !resp.data?.source_id ) {
					this.toaster.error(
						this.lService.translate('errors.unsuccessful'),
						this.lService.translate('sources.title')
					);
				}
				return resp;
			}));
	}

	delete(params: SourcesDeleteRequest): Observable<SourcesDeleteResponse> {
		return this.api.loader<SourcesDeleteResponse>('Sources.Delete', params, this.loading$);
	}

	export(total: number, params: RPCRequestParams): Observable<void> {
		params = {
			...params,
			Paging: { Skip: 0, Take: total }
		};
		return this.exportService.export(
			this.config.api,
			'Sources.Read',
			this.prepareParams(params),
			this.fields.headers,
			'sources'
		);
	}

	findSourceInRules(params: RulesFindSourceInRulesRequest): Observable<RulesFindSourceInRulesResponse> {
		return this.api.loader<RulesFindSourceInRulesResponse>(
			'Rules.FindSourceInRules',
			params,
			this.loading$
		);
	}

	private prepareParamsBeforeCreate(params: SourcesUpdateRequest): SourcesUpdateRequest {
		for ( const element in params ) {
			if (element !== null && params[element] !== null) {
				if (element === 'Ttl' || element === 'RequestTimeout' || element === 'Port'
					|| element === 'Retries'  || element === 'SenderPort' || element === 'StatusRequestDelay') {
					params[element] = Number(params[element]);
				}
				if (element === 'MccSet' || element === 'MccmncTranslation' || element === 'MccmncTranslationBy'
					|| element === 'MccmncTranslationBy2' || element === 'ErrorCodeTranslation'
					|| element === 'ResponseCodeCacheList') {
					params[element] = JSON.parse(params[element]!);
				}
			}
		}
		return params;
	}

	private prepareParams(params: ReadRequest): SourcesReadRequest {
		return {
			offset: params.Paging?.Skip ?? 0,
			limit: params.Paging?.Take ?? parseInt(this.storage.get(STORAGE.PAGE_SIZE) ?? '25', 10),
			active: 'source_name',
			direction: 'asc',
			filter: params.Filters
				? params.Filters.map((filter) => {
					return this.parseFilterField(filter);
				})
				: []
		};
	}

	private parseFilterField(filter: Filter): { key: string, value: string } {
		const variable = filter.Field;
		const value = filter.Value;
		switch (variable) {
			case 'Name':
				return { key: 'source_name', value: value as string };
			case 'Type':
				return { key: 'source_provider_name', value: value as string };

			default:
				return { key: variable, value: value + '' };
		}
	}

}
