<div class="layout">
	<alaris-card size="auto">
		<div [alarisLoading]="loading$">
			<div class="container">
				<div class="logo">
					<div [style]="config.logoBg" class="img"></div>
				</div>

				<div class="content">
					<div class="head">
						<div class="title">
							{{ config.title }}
						</div>
						<div class="sub-title">
							{{ 'signIn.state.login.welcome' | translate }}
						</div>
					</div>
					<form (ngSubmit)="signIn()" [formGroup]="signInForm" class="form">
						<alaris-input (autofill)="updateValidators($event, signInForm.controls.login)"
									  [label]="'users_roles.users.login' | translate"
									  [type]="InputType.LOGIN" formControlName="login">
						</alaris-input>

						<alaris-input (autofill)="updateValidators($event, signInForm.controls.password)"
									  [label]="'users_roles.users.password' | translate"
									  [type]="InputType.PASSWORD"
									  formControlName="password">
						</alaris-input>

						<button [disabled]="signInForm.invalid" alaris-button bType="primary" type="submit">
							{{ 'signIn.title' | translate }}
						</button>
					</form>

				</div>

				<div class="footer">
					<div class="info">
						<alaris-language></alaris-language>
						@if (config.showLink) {
							<div>
								{{ 'gl.powered_by' | translate }}
								<alaris-link href="https://alarislabs.com" type="primary">Alarislabs</alaris-link>
							</div>
						} @else {
							<div></div>
						}
					</div>
					@if (config.supportEmail) {
						<alaris-link [href]="'mailto:' + config.supportEmail"
									 type="secondary">
							{{ 'gl.auth.getHelp' | translate }}
						</alaris-link>
					}
				</div>
			</div>
		</div>
	</alaris-card>
</div>
