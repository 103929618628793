<form (ngSubmit)="save()" [formGroup]="sourceForm">
	<div [alarisLoading]="sourcesService.loading$">
		<div class="edit-panel-header">
			<alaris-icon name="icon-add-contact"></alaris-icon>
			{{ source.SourceId ? ('sources.edit' | translate) : ('sources.add' | translate) }}
		</div>
		<div [alarisFade]="'vertical'" class="form-group panel-content">

			<alaris-input formControlName="Name" [errors]="errors">
				{{ 'sources.name' | translate }}
			</alaris-input>

			<alaris-select2 [alarisLoading]="providersService.loading$"
							[label]="'sources.type' | translate"
							formControlName="Type" [errors]="errors">
				<alaris-select-filter2 [formControl]="providersFilter"></alaris-select-filter2>
				<alaris-options2 *alarisVirtualFor="let provider of providers; let index"
								 [value]="provider.Name"
								 [virtualIndex]="index">{{ provider.Name }}
				</alaris-options2>
			</alaris-select2>

			@for (field of (providersService.map.get(sourceForm.controls.Type.value)?.Fields ?? []); track field.field_name; let index = $index) {
				<alaris-filter-control [control]="getControl(field)"
									   [field]="getField(field, index)"
									   [alarisTooltip]="getExample(field)"
									   [overflow]="false"
									   [errors]="errors">
				</alaris-filter-control>
			}

			<button (click)="reset()" alaris-button bType="secondary" class="reset-button" size="lg" type="button">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'gl.reset' | translate }}
			</button>
		</div>
		<div class="edit-panel-footer">
			<button [disabled]="sourceForm.invalid || !sourceForm.dirty"
					alaris-button bType="primary"
					size="lg"
					type="submit">
				<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
				{{ 'gl.submit' | translate }}
			</button>
			<button (click)="close()" alaris-button bType="outline" size="lg" type="button">
				{{ 'actions.close' | translate }}
			</button>
		</div>
	</div>
</form>
