import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Source } from '@helpers/types/models';
import { exist } from '@campaign-portal/namespace/common/id';
import { SourcesService } from '../sources.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-sources-delete-dialog',
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesDeleteDialogComponent implements OnInit {

	rules?: number[];

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(DIALOG_DATA) public readonly data: { source: Source<exist> },
		public readonly sourcesService: SourcesService,
		private readonly dialogRef: DialogRef<boolean>
	) {
	}

	ngOnInit(): void {
		this.sourcesService.findSourceInRules({ choices: [this.data.source.SourceId] })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if ( resp.rules_id_list && resp.rules_id_list.length > 0 ) {
					this.rules = resp.rules_id_list;
				}
			});
	}

	confirm(): void {
		if ( this.rules ) {
			this.dialogRef.close();
			return;
		}
		this.sourcesService.delete({
			sourceId: this.data.source.SourceId
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				if ( resp.data?.source_id ) {
					this.dialogRef.close(true);
				}
			});
	}

	close(): void {
		this.dialogRef.close(false);
	}

}
