import { Injectable } from '@angular/core';
import { AlarisApiService, ExtendableRefBookService } from '@campaign-portal/components-library';
import { Observable } from 'rxjs';
import { SourcesReadAvailableLocalAddressesResponse } from '@helpers/types/hlr';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class LocalAddressesService extends ExtendableRefBookService<string> {

	constructor(
		private readonly api: AlarisApiService
	) {
		super();
	}

	override load(): Observable<ReadResponse<string[]>> {
		return this.api.loader<SourcesReadAvailableLocalAddressesResponse>(
			'Sources.ReadAvailableLocalAddresses', {}, this.loading$
		).pipe(
			map((resp) => {
				return {
					Success: !!resp.data,
					Total: resp.data.length,
					Data: resp.data
				};
			}),
			map((resp) => {
				return this.process(resp);
			})
		);
	}

	override process(resp: ReadResponse<string[]>): ReadResponse<string[]> {
		this.list$.next(resp.Data);
		return resp;
	}
}
