import { Inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import {
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisToasterService
} from '@campaign-portal/components-library';
import { STORAGE } from '@helpers/types/consts';
import { DOCUMENT } from '@angular/common';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import * as XLSX from 'xlsx';
import { utils } from 'xlsx';
import { ProductsService } from '@helpers/repo/products.service';

export type ExportInterface = 'users' | 'rules' | 'clients' | 'sources' | string;

@Injectable({
	providedIn: 'root'
})
export class ExportService {

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		public readonly productService: ProductsService,
		private readonly storage: AlarisLocalStorageService,
		private readonly lService: AlarisLanguageService,
		private readonly toaster: AlarisToasterService
	) {
	}

	export(
		url: string,
		method: string,
		params: Record<string, unknown>,
		fields: EntityField[],
		type: ExportInterface
	): Observable<void> {
		const jwt = this.storage.get(STORAGE.TOKEN);
		fields = fields.map((item) => {
			item.name = this.lService.translate(item.name);
			return item;
		});
		const worker = new Worker('assets/js/export.worker.js');
		worker.postMessage({ url, method, params, fields, jwt, type });

		return new Observable<void>(observer => {
			worker.onmessage = ({ data: { success, message } }): void => {
				try {
					if ( success ) {
						const data = message.data.map((row: object) => Object.keys(message.columns)
							.reduce((obj, key) => {
								const productNamesArr: string[] = [];
								switch(key) {
									case 'productId':
										(obj as any)[this.lService.translate(message.columns[key])]
											= this.productService.clientProducts.find((product) =>
												product.product_id.toString() === (row as any)[key])?.product_caption;
										break;
									case 'productList':
										if ((row as any)[key]) {
											(row as any)[key].forEach((productId: string) => {
												productNamesArr
													.push(this.productService.clientProducts.find((product) =>
														product.product_id.toString() === productId)?.product_caption!)
													.toString();
											});
										}
										(obj as any)[this.lService.translate(message.columns[key])]
											= productNamesArr.toString();
										break;
									case  'firstName':
										if ((row as any).Details[key]) {
											(obj as any)[this.lService.translate(message.columns[key])]
												= (row as any).Details[key].toString();
										}
										break;
									default:
										if ((row as any)[key] !== undefined
											&& (row as any)[key] !== null
												&& (row as any)[key] !== '') {
											(obj as any)[this.lService.translate(message.columns[key])]
												= (row as any)[key].toString();
										}
										break;
								}
								return obj;
							}, {}));
						const ws = utils.aoa_to_sheet([[type]]);
						utils.sheet_add_json(ws, data, {origin:1});
						const wb = utils.book_new();
						utils.book_append_sheet(wb, ws, type);
						const u8 = XLSX.write(wb,
							{ type: 'array', bookType: 'xlsx' }
						);
						const blobFile = new Blob([u8]);
						const file = window.URL.createObjectURL(blobFile);
						const anchor = this.document.createElement('a');
						anchor.download = (method.split('.')[0] ?? 'export')
							+ `_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`
							+ '.xlsx';
						anchor.href = file;
						anchor.click();
						window.URL.revokeObjectURL(file);
					} else {
						throw new Error('Internal error');
					}
				} catch (e) {
					console.error(e);
					this.toaster.error(this.lService.translate('errors.rpc.-32003'));
					observer.error(e);
					observer.complete();
				}
				observer.next();
				observer.complete();
			};
			// Cleanup logic when unsubscribed
			return () => {
				worker.terminate();
			};
		}).pipe(
			finalize(() => {
				worker.terminate(); // Ensure worker is terminated when observable completes or errors
			})
		);
	}

}
