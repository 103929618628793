import { Injectable } from '@angular/core';
import { AbstractTableComplexSettingsService } from '@campaign-portal/components-library/lib/types';
import {
	TableSettingsReadResponse,
	TableSettingsUpdateResponse
} from '@campaign-portal/namespace/entities/table-settings/specs';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TableSettingsService implements AbstractTableComplexSettingsService {
	constructor() {
	}

	update(): Observable<TableSettingsUpdateResponse> {
		return of({ Success: true, Total: 0, Data: [] });
	}

	read(): Observable<TableSettingsReadResponse> {
		return of({ Success: true, Total: 0, Data: [] });
	}
}
