import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AlarisConfigService, AlarisLocalStorageService } from '@campaign-portal/components-library';
import { USERS_AND_ROLES_API } from '../../app.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { STORAGE } from '@helpers/types/consts';

const methodTypes = ['Create', 'Update', 'Delete'];

@Injectable()
export class ExportConfig implements HttpInterceptor {

	constructor(
		private readonly config: AlarisConfigService,
		private readonly storage: AlarisLocalStorageService,
		@Inject(USERS_AND_ROLES_API) private readonly userAndRolesApi: { url: string }
	) {
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if ( req.url.includes(this.config.api) || req.url.includes(this.userAndRolesApi.url) ) {

			const methodType = req.body?.method?.split('.')[1];
			if ( methodType && methodTypes.includes(methodType) ) {
				return next.handle(req).pipe(map(event => {
					if ( event instanceof HttpResponse ) {
						fetch(
							this.config.api + '?' + 'HlrSettings.ExportConfig',
							{
								method: 'POST',
								headers: {
									'Authorization': `Bearer ${this.storage.get(STORAGE.TOKEN)}`,
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({
									id: new Date().getTime(),
									jsonrpc: '2.0',
									method: 'HlrSettings.ExportConfig'
								})
							}
						).then();
					}
					return event;
				}));
			}
		}

		return next.handle(req);
	}
}
